import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { post } from 'jquery';

@Injectable()
export class HomeService {
    constructor(private http: HttpClient) { }
    get(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user));
    }
    getblock(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get_block_all`).pipe(map(user => user));
    }

    getMerachant(search:any,page:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/get_merchant?search=`+search+'&page='+page).pipe(map(user => user));
    }


    getDriver(search:any,page:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-driver?search=`+search+'&page='+page).pipe(map(user => user));
    }
    getMerchantDropdown():Observable<any>{
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/drop-down`).pipe(map(user => user));
    }
    updateblock() : Observable<any>{
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/driver/update-block`).pipe(map(user => user));
    }


    getNewrequests(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-newrequests?search=`+search+'&page='+page).pipe(map(user => user));
    }

    status_update(id: string, status: any) {
        const formData: FormData = new FormData();
       
       
        formData.append('status', status);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/update_status/`+ id, formData).pipe(map(data => {
            return data;
        }));

    }
    verify_profile(id:any,status:any){
        const formData: FormData = new FormData();
        formData.append('status',status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/verify_profile/`+ id, formData).pipe(map(data =>{
        return data;
        }));
    }
  
     
    getVerifyDriver(search:any,page:any): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/photo_uploaded_driver?search=`+search+'&page='+page).pipe(map(user => user));
    }
    
  

    saveblock(name:any,start_time:any,end_time:any,order_charges:any,tips:any){
        const formData: FormData = new FormData();
        formData.append('warehouseId', name);
        formData.append('start_time',start_time);
        formData.append('end_time',end_time);
        formData.append('order_charges',order_charges);
        formData.append('tips',tips);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/driver/block`,formData).pipe(map(user => {
            return user;
        }));
    }


    getwarehouse(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/merchant/getwarehouse`).pipe(map(user => user));

    }


    status_accept(id: string, admin_accept: any) {
        const formData: FormData = new FormData();
       
       
        formData.append('admin_accept', admin_accept);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/update_status/`+ id, formData).pipe(map(data => {
            return data;
        }));

    }
    

    getDriverdetails(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get_adminvehile/` + id).pipe(map(user => user));
    }

    //category
    addCategory(main_category:any,type:any){
        const formData: FormData = new FormData();
        formData.append('main_category', main_category);
        formData.append('type',type);


        
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/add_maincategory`,formData).pipe(map(user => {
            return user;
        }));
    }

    updateCategory(id:any,main_category:any,type:any){
        const formData: FormData = new FormData();
        formData.append('main_category', main_category);
        formData.append('type',type);
       
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/add_maincategory`+id,formData).pipe(map(user => {
            return user;
        }));
    }
    

    getSingleCategory(id:any){
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get_main/`+ id).pipe(map(data =>{
        return data;
        }));
    }

    getAllCategory(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get_maincategory_list?search=`+search+'&page='+page).pipe(map(user => user));
    }
}
