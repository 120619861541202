import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService, OrderService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { identifierModuleUrl } from '@angular/compiler';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class addCategoryComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

  addCategoryForm: FormGroup;
  orders$: any[];
  orderss$: any[];
  loading = false;
  orderId: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
  status_text = '';
  search_data = '';
  search_status = '';
  view_img: any;
  total: any;
  from: any;
  vallen: any;
  drivers: any;
  bulk_type: any;
  imgpath: any;
  datefrom: any;
  to: any;
  from_date: any;
  to_date: any;
  searchUser: any;
  limit = 10;
  code = 1;
  pro: any;
  items: any;
  routeParam: any;
  provider: any;
  add_product: any;
  val1: any;
  bulk_add = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  cat_type = "images";
  val_event: any;
  users$: any;
  dateto: any;
  sortasc: any;
  sortdecs: any;
  myInputVariable: ElementRef;
  pro$: any;
  current_date: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bulkFile: File = null;
  dtTrigger2: Subject<any> = new Subject();
  page: any
  pageCounter: any
  topage: any
  submitted = false;
  btnType = 'Add'

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private homeService: HomeService,
  ) { }
  type = '1';
  seacrhtxt = '';
  S3link = 'http//rrc-chipsy.s3.ap-south-1.amazonaws.com'




  ngOnInit() {

    //  alert(this.getTab);

    this.img_add = true;
    this.bulk_type = "images";
    this.sortasc = false;
    this.sortdecs = true;
    var date = new Date();
    // this.current_date = this.formatDate(date);

    this.refreshOnce()

    this.homeService.getAllCategory('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      this.dtTrigger.next();

      return false;
      this.dtTrigger.next();
    }, error => {
      console.error(error);
    });

  }


  refreshOnce() {

    this.homeService.getAllCategory('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      this.dtTrigger.next();

    });
    
    this.addCategoryForm = this.formBuilder.group({
      id: [""],
      main_category: ["", [Validators.required]],
      type: ["", [Validators.required, Validators.maxLength(25)]],
    });
  }

  get f(): any {
    return this.addCategoryForm.controls;
  }

  search(e) {

    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.seacrhtxt = e.target.value;

    this.homeService.getAllCategory(e.target.value, 1).subscribe(data => {
      if (data.code == 0) {
        this.code = data.code;
      }
      // this.vallen = this.orders$.length;
      // this.orders$ = data && data.result ? data.result : '';
      // this.curPage = data && data.currentPage ? data.currentPage : '';
      // this.total = data && data.result && data.result.length ? data.result.length : '';
      // this.from = data && data.currentPage ? data.currentPage : '';
      // this.to = data && data.totalPages ? data.totalPages : '';
      // this.last_page = data && data.totalPages ? data.totalPages : '';

      this.vallen = data.data.totalPages;
      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      this.dtTrigger.next();

    })
  }

  pageChange(e) {
    this.homeService.getAllCategory(this.seacrhtxt, e).subscribe(data => {
      // this.orders$ = data.result;
      // this.curPage = data.currentPage;
      // this.total = data.result.length;
      // this.from = data.currentPage;
      // this.to = data.totalPages;
      // this.last_page = data.totalPages;

      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      this.dtTrigger.next();

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  //bulk code
  add_bulk(val) {
    this.bulk_type = val;
    // this.add_product = false;
    this.bulk_add = true;

  }

  // refresh() {
   
  // }


  onSubmit(form, formWrap) {

    this.submitted = true;
    

    if (this.addCategoryForm.invalid) {
      this.toastr.error("Enter All Fields")
      return;
    }


    this.loading = true;

    if (this.f.id.value) {
      this.btnType = 'Add'
      this.homeService.updateCategory(this.f.id.value,this.f.username.value, this.f.password.value)
          .subscribe(
              data => {

              })
    } else {
      this.homeService.addCategory(this.f.main_category.value, this.f.type.value)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.toastr.success(data.status.message)
              this.loading = false;
              this.refreshOnce();
              // this.refresh();
            }
          })
    }
    // stop here if form is invalid







  }

  triggerBulk() {
    let element: HTMLElement = document.querySelector('input[id="bulk"]') as HTMLElement;
    element.click();
  }


  resetBulk() {
    this.myInputVariable.nativeElement.value = "";
  }




  viewDet(e, user) {
    if (user.type == "images") {
      this.view_img = "images";
    } else if (user.type == "video") {
      this.view_img = "video";
    } else {
      this.view_img = "audio";
    }
    // alert(this.view_img)
    this.imgpath = this.media_domain + user.image;

    $('#managebenModal').modal('show');

    // this.router.navigate(['/order-details'], { queryParams: { id: user._id } });
  }

  editDet(e, user) {
    this.btnType = 'Update'
    this.homeService.getSingleCategory(user._id)
        .subscribe(
          data => {
            if (data.status.code == 0) {
              this.addCategoryForm.patchValue({
                  id:data.data[0]._id,
                  main_category:data.data[0].main_category,
                  type:data.data[0].type,
                })
              // this.toastr.success(data.status.message)
              // this.loading = false;
              // this.refreshOnce()
            }
          })
    // alert(JSON.stringify(user));
    // this.router.navigate(['/editDetails'], { queryParams: { id: user._id, category: user.category } });
  }

  onBack() {
    // this.router.navigate(["/home"]);
    // this.addCategoryForm.reset();
    // this.addCategoryForm.patchValue({
    //   main_category:'',
    //   type:''
    // })
    this.refreshOnce()
    this.btnType='Add';

  }

}
