// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: "rrc",
  production: true,
  // apiUrl:'http://services.mapeazy.com/',
  // apiUrl: 'http://43.204.227.222:3051/', // development-server

  // apiUrl: 'http://192.168.0.195:3051/', //rakshitha-local-server

  apiUrl: "https://rrc-api.manipal.edu/",
  oneSignalAppId: "b48d5f14-a656-4383-b34e-725affb0180f", //dev
  media_domain: "https://rrc-chipsy.s3.ap-south-1.amazonaws.com/development", //dev
  // media_domain1: 'https://rrc-chipsy.s3.ap-south-1.amazonaws.com/development',//dev
  apiPrefix: "api/v1",
  imgWidth: "500",
  imgHeight: "500",
  servImgWidth: "720",
  servImgHeight: "480",
  servCovImgWidth: "360",
  servCovImgHeight: "200",
  banImgWidth: "64",
  banImgHeight: "64",
  gameTypeWidth: "72",
  gameTypeHeight: "72",
  bannerWidth: "1920",
  bannerHeight: "560",
  subcatWidth: "330",
  subcatHeight: "310",
  maincatWidth: "350",
  maincatHeight: "374",
  iconWidth: "96",
  iconHeight: "96",
  branchWidth: "264",
  branchHeight: "264",
  prodImgWidth: "700",
  prodImgHeight: "700",
  promotionWidth: "1920",
  promotionHeight: "560",
  WEBLINK: "https://RRC.com/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
