<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>
<div class="wrapper flt">
    <div class="container">
        
        <div class="content-wrapper ">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <!-- <ol class="breadcrumb">
                                <li class="breadcrumb-item active">audio Details</li>
                            </ol> -->
                        </div>
                    </div>
                </div>
            </div>
  
                <section class="content">
                    <div class="container-fluid">
                        <div class="create_order_main">
                            <!-- Nav tabs -->
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#home">Create audio details</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#menu1">Bulk audio excel details upload </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#menu2">Bulk audio files upload</a>
                                </li>
                            </ul>
                            <!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane active" id="home">
                                    <div class="card card-default">
                                        <div class="card-header">
                                            <h3 class="card-title">Create single audio details</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body">
                                         
                                                    <div class="input_body flt">
                                                        <form [formGroup]="createForm" #myForm="ngForm" (ngSubmit)="onSubmit(createForm, myForm)"
                                                            class="">
                                                            <div class="row">

                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b flt">
                                                                        <label class="form-control-label" for="itemName">Item
                                                                            Name</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="itemName" formControlName="itemName" onload="this.value=''"
                                                                            placeholder="Item Name" autocomplete="off"
                                                                            [ngClass]="{ 'is-invalid': (f.itemName.touched || submitted) && f.itemName.errors }" />
                                                                        <div *ngIf="(f.itemName.touched || submitted) && f.itemName.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.itemName.errors?.required">Item Name Is
                                                                                Required</div>
                                                                        </div>
                                                                        <div *ngIf="(f.itemName.touched || submitted) && f.itemName.errors?.maxlength"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.itemName.errors?.maxlength">Maximum 25
                                                                                characters
                                                                                are
                                                                                allowed
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="Category">Category</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="Category" formControlName="Category" onload="this.value=''"
                                                                            placeholder="Category" autocomplete="off"
                                                                            [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }" />
                                                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Category.errors?.required">Category Is Required
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.maxlength"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Category.errors?.maxlength">Maximum 25 characters
                                                                                are
                                                                                allowed
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                           
                                                 
                                                        
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="PerformerInformerCollection">Performer Informer Collection</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="PerformerInformerCollection" formControlName="PerformerInformerCollection" onload="this.value=''"
                                                                            placeholder="Performer Informer Collection" autocomplete="off"
                                                                            [ngClass]="{ 'is-invalid': (f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors }" />
                                                                        <div *ngIf="(f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.PerformerInformerCollection.errors?.required">Performer Informer Collection Is Required
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="(f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors?.maxlength"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.PerformerInformerCollection.errors?.maxlength">Maximum 25 characters
                                                                                are
                                                                                allowed
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            
                                
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="audio_no">Audio
                                                                            Number</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="audio_no" formControlName="audio_no" 
                                                                            placeholder="Audio Number" autocomplete="off"
                                                                           
                                                                            [ngClass]="{ 'is-invalid': (f.audio_no.touched || submitted) && f.audio_no.errors }" />
                                                                        <div *ngIf="(f.audio_no.touched || submitted) && f.audio_no.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.audio_no.errors?.required">Audio Number
                                                                                Is Required</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="recordingplace">Recording Place</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="recordingplace" formControlName="recordingplace" onload="this.value=''"
                                                                            placeholder="Recording Place" autocomplete="off"
                                                                            [ngClass]="{ 'is-invalid': (f.recordingplace.touched || submitted) && f.recordingplace.errors }" />
                                                                        <div *ngIf="(f.recordingplace.touched || submitted) && f.recordingplace.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.recordingplace.errors?.required">Recording Place Is Required
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="(f.recordingplace.touched || submitted) && f.recordingplace.errors?.maxlength"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.recordingplace.errors?.maxlength">Maximum 25 characters
                                                                                are
                                                                                allowed
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="recordingdate">Recording Date</label>
                                                                        <input autocomplete="off" type="date" class="form-control username password_box"
                                                                            id="recordingdate" formControlName="recordingdate" onload="this.value=''"
                                                                            [ngClass]="{ 'is-invalid': (f.recordingdate.touched || submitted) && f.recordingdate.errors }"
                                                                            max="{{currentDate | date:'yyyy-MM-dd'}}" />
                                                                        <div *ngIf="(f.recordingdate.touched || submitted) && f.recordingdate.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.recordingdate.errors?.required">Recording Date Is Required</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="numberOfAudios">Number Of
                                                                            Audios</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="numberOfAudios" formControlName="NumberOfAudios" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                            placeholder="Number Of Audios" autocomplete="off"
                                                                           
                                                                            [ngClass]="{ 'is-invalid': (f.NumberOfAudios.touched || submitted) && f.NumberOfAudios.errors }" />
                                                                        <div *ngIf="(f.NumberOfAudios.touched || submitted) && f.NumberOfAudios.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.NumberOfAudios.errors?.required">Number Of
                                                                                Audios Is Required</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="Price">Price</label>
                                                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                                                            id="Price" formControlName="Price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                            placeholder="Price" autocomplete="off"
                                                                           
                                                                            [ngClass]="{ 'is-invalid': (f.Price.touched || submitted) && f.Price.errors }" />
                                                                        <div *ngIf="(f.Price.touched || submitted) && f.Price.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Price.errors?.required">Price Is Required</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                          
                                                                <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="Audios">Choose a audio</label>
                                                                        <input autocomplete="off" type="file" accept="audio/*"
                                                                            class="choose_file_box form-control username password_box" id="Audios"
                                                                            formControlName="Audios" placeholder="Number Of Audios" autocomplete="off"
                                                                            (change)="handleFileInput1($event, $event.target.files)"
                                                                            [ngClass]="{ 'is-invalid': (f.Audios.touched || submitted) && f.Audios.errors }" />
                                                                        <div *ngIf="(f.Audios.touched || submitted) && f.Audios.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Audios.errors?.required">Please select a file to upload</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            

                                                                <div class="col-sm-4">
                                
                                                                </div>
                                
                                                             
                                                                <div class="col-sm-4">
                                                                    <div class="addinfo_btn flt flex">
                                                                        <div class="submit_btn">
                                                                            <button [disabled]="createForm.invalid">Submit</button>
                                                                        </div>
                                                                        <div class="cancel_btn">
                                                                            <button (click)="onBack()">Back</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                           
                                        </div>
                                        <!-- /.row -->
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="menu1">
                                    <form  [formGroup]="audioForm">
                                    <div class="card card-default">
                                        <div class="card-header">
                                            <h3 class="card-title  card-title1">Bulk audio excel details upload</h3>
                                            <button class="btn btn-primary excelsample" (click)="excel()">Download Format</button>

                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body">
                                        
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputFile">File input</label>
                                                    <form [formGroup]="excelForm">
                                                        <file-upload formControlName="files" [multiple]="multiple" [animation]="animation" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></file-upload>
                                                    </form>
                                                    <div *ngIf="(submitted1) && b.files.errors?.required"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="b.files.errors?.required"> Please select a file to upload</div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                            <div class="row">
                                            <div class="submit_btn">
                                            <button type="submit" (click)="bulkSubmit()" >Upload</button>
                                            </div>
                                            <div class="cancel_btn">
                                            <button (click)="onBack()">Back</button>
                                            </div>
                                            </div>
                                            </div>

                                           
                                        </div>
                                        <!-- /.row -->
                                    </div>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="menu2">
                                    <div class="card card-default">
                                        <div class="card-header">
                                            <h3 class="card-title">Bulk audio files upload</h3>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputFile">File input</label>
                                                    <form [formGroup]="audioForm">
                                                        <file-upload formControlName="files" [multiple]="multiple" [animation]="animation"  accept="audio/*"></file-upload>
                                                    </form>
                                                    <div *ngIf="(submitted1) && i.files.errors?.required"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="i.files.errors?.required"> Please select a file to upload</div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 submit_btn">
                                                <div class="row">
                                                <div class="submit_btn">
                                                <button type="submit" (click)="bulkaudioSubmit()" >Upload</button>
                                                </div>
                                                <div class="cancel_btn">
                                                    <button (click)="onBack()">Back</button>
                                                    </div>
                                            </div>
                                            </div>

                                        </div>
                                        <!-- /.row -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
        </div>
    </div>
</div>