import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { routing } from './app.routing';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
// tslint:disable-next-line: max-line-length
import { BannersService, AlertService, AuthenticationService, HomeService, ModalService, RoleService, SettingsService, OrderService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { DataTablesModule } from 'angular-datatables';
import { LSelect2Module } from 'ngx-select2';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPrintModule } from 'ngx-print';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { editDetailsComponent } from './edit-details/edit-details.component'

import { NgxPaginationModule } from 'ngx-pagination';
import { SiteFooterComponent } from './_layouts/site-footer/site-footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';;
import { addDetailsComponent } from './addDetails/AddDetails.component';
import { viewDetailsComponent } from './viewDetails/viewDetails.component';
import { userInformationComponent } from './user-information/user-information.component';
import { manageOrdersComponent } from './manage-orders/manage-orders.component';
import { addCategoryComponent } from './add-category/add-category.component';


import { bulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { DatePipe } from '@angular/common';
import { ImageuploadComponent } from './image-upload/image-upload.component';
import { VideouploadComponent } from './video-upload/video-upload.component';
import { AudiouploadComponent } from './audio-upload/audio-upload.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';






@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        FileUploadModule,
        routing,
        DataTablesModule,
        LSelect2Module,
        SelectDropDownModule,
        NgxPrintModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        })
    ],
    declarations: [
        AppComponent,
        ModalComponent,
        HomeComponent,
        LoginComponent,
        SiteHeaderComponent,
        addDetailsComponent,
        viewDetailsComponent,
        userInformationComponent,
        addCategoryComponent,
        manageOrdersComponent,
        bulkUploadComponent,
        editDetailsComponent,
        ImageuploadComponent,
        SiteFooterComponent,
        ForgotPasswordComponent,
        VideouploadComponent,
        AudiouploadComponent
    ],

    providers: [
        DatePipe,
        AuthSuperadminGuard,
        AuthVendorGuard,
        AlertService,
        AuthenticationService,
        SettingsService,
        HomeService,
        ModalService,
        BannersService,
        RoleService,
        OrderService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
