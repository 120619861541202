<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>





<div class="wrapper flt">
    <div class="container">
        <div class="upload_sections flt">
            <div class="upload_section_main flt flex">
                <div class="upload_btn_main">
                    <input (keyup)="search($event)" class="form_control_search" type="text" name="custid"
                        placeholder="Search for Order ID/Name/Email/Mobile">
                    <img class="search_icon" src="../../assets/images/search.png" />
              
                        <button class="reset_btn" (click)="reset()">Reset</button>
                    
                </div>
            </div>

           
        </div>
        <div class="inner_home flt">
            <div class="count_main flex flt">
                <div class="image_count flt">
                    <a (click)="pending($event)" [ngClass]="{ 'active': pend_status == true}">
                        <div class="info-box">
                            <span class="info-box-icon cmn-icon bg-success">
                                {{none?none:0}}
                            </span>
                            <div class="info-box-content">
                                PENDING
                            </div>
                        </div>
                    </a>
                </div>
                <div class="image_count flt">
                    <a (click)="acceptdata($event)" [ngClass]="{ 'active': accept_status == true}">
                        <div class="info-box">
                            <span class="info-box-icon cmn-icon bg-success">
                                {{accept?accept:0}}
                            </span>

                            <div class="info-box-content flt">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text"></span> -->
                                ACCEPT
                            </div>
                        </div>
                    </a>
                </div>
                <div class="image_count flt">
                    <a (click)="rejectdata($event)" [ngClass]="{ 'active': reject_status == true}">
                        <div class="info-box">
                            <span class="info-box-icon cmn-icon bg-success">
                                {{reject?reject:0}}
                            </span>
                            <div class="info-box-content">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text">Videos</span> -->
                                REJECT
                            </div>
                        </div>
                    </a>
                </div>
                <div class="image_count flt">
                    <a (click)="refunddata($event)" [ngClass]="{ 'active': refund_status == true}">
                        <div class="info-box">
                            <span class="info-box-icon cmn-icon bg-success">
                                {{refund?refund:0}}
                            </span>
                            <div class="info-box-content">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text">Image</span> -->
                                REFUND
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>


        <div class="content-wrapper flt">
            <section class="content dashboard_box flt">
                <div class=" flt">
                    <div class="card">
                        <div class="card-body">
                            <div class="prod_adding table-responsive">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Sr No.</th>
                                            <th>Order ID</th>
                                            <th>Category</th>
                                            <th>Item Name</th>
                                            <th>Purpose</th>
                                            <th>Amount</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Add / Edit URL</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td *ngIf="user && user.order_id;else Order_id">{{user.order_id}}</td>
                                            <ng-template #Order_id>
                                                <td>-</td>
                                            </ng-template>

                                           
                                            <td *ngIf="user.audio && user.image && user.video">
                                                <span *ngIf="user && user.audio[0] && user.audio[0].mediatype">
                                                {{user.audio[0].mediatype}}
                                               </span>
                                                <span *ngIf="user && user.image[0] && user.image[0].mediatype">
                                                {{user.image[0].mediatype}}
                                               </span>
                                                <span *ngIf="user && user.video[0] && user.video[0].mediatype">
                                                 {{user.video[0].mediatype}}
                                                </span></td>
                                           
                                                <!-- <div *ngIf="user.audio">
                                                    <td>
                                                    <span  *ngFor="let item of user.audio;let dataLen=index">
                                                    {{item.itemName}}<span *ngIf="((user.audio).length > 1) && (((user.audio).length-1) != dataLen)">,</span>
                                                    <span *ngIf="((user.audio).length-1) == dataLen"></span></span></td>
                                                </div>
                                          
                                                <div *ngIf="user.image ">
                                                    <td>
                                                    <span  *ngFor="let item of user.image;let dataLen=index">
                                                    {{item.itemName}}<span *ngIf="((user.image).length > 1) && (((user.image).length-1) != dataLen)">,</span>
                                                    <span *ngIf="((user.image).length-1) == dataLen"></span></span></td>
                                                </div>

                                                <div *ngIf="user.video ">
                                                    <td>
                                                    <span  *ngFor="let item of user.video;let dataLen=index">
                                                    {{item.itemName}}<span *ngIf="((user.video).length > 1) && (((user.video).length-1) != dataLen)">,</span>
                                                    <span *ngIf="((user.video).length-1) == dataLen"></span></span></td>
                                                </div>

                                                    -->
    


                                                 <!-- <div *ngIf="user.video">
                                                    <td *ngIf="user && user.video[0] && user.video[0].itemName">
                                                    {{user.video[0].itemName}}</td>
                                                 </div> -->
                                                 <td>
                                                    <div *ngIf="user.audio">
                                                        
                                                        <span  *ngFor="let item of user.audio;let dataLen=index">
                                                        {{item.itemName}}<span *ngIf="((user.audio).length > 1) && (((user.audio).length-1) != dataLen)">,</span>
                                                        <span *ngIf="((user.audio).length-1) == dataLen"></span></span>
                                                    </div>
                                              
                                                    <div *ngIf="user.image ">
                                                      
                                                        <span  *ngFor="let item of user.image;let dataLen=index">
                                                        {{item.itemName}}<span *ngIf="((user.image).length > 1) && (((user.image).length-1) != dataLen)">,</span>
                                                        <span *ngIf="((user.image).length-1) == dataLen"></span></span>
                                                    </div>
    
                                                    <div *ngIf="user.video ">
                                                    
                                                        <span  *ngFor="let item of user.video;let dataLen=index">
                                                        {{item.itemName}}<span *ngIf="((user.video).length > 1) && (((user.video).length-1) != dataLen)">,</span>
                                                        <span *ngIf="((user.video).length-1) == dataLen"></span></span>
                                                    </div>
    
                                                </td>
                                            <ng-template #ItemName>
                                                <td>-</td>
                                            </ng-template>

                                            <td *ngIf="user && user.proper_status;else Proper_status">{{user.proper_status}}</td>
                                            <ng-template #Proper_status>
                                                <td>-</td>
                                            </ng-template>

                                            <td>{{user && user.cartdetails ? user.cartdetails[0].total_amount : 0}}</td>
                                            <td *ngIf="!user.user">-</td>
                                            <td *ngIf="!user.user">-</td>
                                            <td *ngIf="!user.user">-</td>
                                            <td *ngIf="user.user">{{user && user.user[0] && user.user[0].name ? user &&
                                                user.user[0] && user.user[0].name : '-'}}</td>
                                            <td *ngIf="user.user">{{user && user.user[0] && user.user[0].email ? user &&
                                                user.user[0] && user.user[0].email : '-'}}</td>
                                            <td *ngIf="user.user">{{user && user.user[0] && user.user[0].mobile ? user
                                                && user.user[0] && user.user[0].mobile : '-'}}</td>
                                            <td>
                                                <button *ngIf="user.link == ''" class="view_cls"
                                                    (click)="addURL(user.order_id)">Add</button>
                                                <button *ngIf="user.link != ''" class="view_cls"
                                                    (click)="editURL(user.order_id)">Edit</button>
                                            </td>

                                            <td>
                                                <select id="status" class="normal_background"
                                                    (change)="addAction(user.order_id,$event)">
                                                    <option value="" [selected]="user.status == 0" disabled>Select
                                                        Action</option>
                                                    <option value="1" [selected]="user.status == 1" [disabled]="!user.link"
                                                   >Accept</option>
                                                       <!--  [disabled]="!user.link" -->
                                                    <option value="2" [selected]="user.status == 2">Reject</option>
                                                    <option value="3" [selected]="user.status == 3">Refund</option>
                                                </select>
                                            </td>

                                           

                                        </tr>
                                    </tbody>
                                </table>
                            </div>



                            <div class="no_data" *ngIf="orders$ && orders$.length<=0 || vallen==0 ">
                                No Data Found</div>
                            <div class="prod_adding">
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                        <div class="shloka_result">
                                            Showing {{pageCounter}}-{{topage}} of {{total}} Data.Page {{curPage}} of
                                            {{last_page}}



                                        </div>
                                    </div>
                                    <div class="col-sm-4" *ngIf="orders$ && orders$.length">

                                        <div class="temple_pagination pg_cls">
                                            <a class="backward" [ngClass]="{'disable':curPage==1}"
                                                (click)="pageChange(curPage-1)">
                                                <i class="fas fa-step-backward"></i></a> Page
                                            <span>{{curPage}}</span> of {{last_page}}<a
                                                [ngClass]="{'disable':curPage==last_page}" class="forward"
                                                (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<div class="modal fade image_view url_modal" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">

                <span>URL</span>
                <button type="button" class="close" data-dismiss="modal" (click)="hideURL()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="manageOrderform">
                    <div class="url_main">

                        <div class="example-wrap b flt">

                            <input type="text" class="form-control" id="url" formControlName="url"
                                placeholder="Enter the URL" autocomplete="off" />
                        </div>
                        <div class="url_action">
                            <div class="submit_btn">
                                <button type="button" (click)="submitURL(manageOrderform)">{{add_edit_btn}}</button>
                            </div>

                            <div class="cancel_btn">
                                <button type="button" (click)="hideURL()">Back</button>
                            </div>
                        </div>


                    </div>
                </form>


            </div>
        </div>
    </div>