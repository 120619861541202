<style>
  button.btn.btn-block.cancel {
    font-weight: bold;
    margin-top: 10px;
  }

  .login-desc {
    padding-bottom: 12px;
    color: white;
  }

  .login-box-msg,
  .register-box-msg {
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
    color: rgb(0, 0, 0);
  }

  .btn-primary {
    background-color: #14182a;
    background-image: none;
    font-weight: bold;
  }

  button.btn.btn-block.cancel {
    font-weight: bold;
    margin-top: 10px;
    color: white;
  }
</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <div class="login-box"> -->
<!-- <div class="login-logo">
    <a href="/forgot-password"><img src="assets/images/logo.png" class="img-responsive" alt="logo"></a>
  </div> -->
<!-- /.login-logo -->

<div class="login-page">
  <div class="login-box">
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <div class="">
          <div class="">
            <div class="login_image">
              <img src="../../assets/images/logo_small2.png" class="img-respsonsive" alt="image">
            </div>
          </div>
          <div class="">
            <div class="login-desc">
              Forgot Password
            </div>
            <div class="" *ngIf="passwordreset==true">
              <p class="login-box-msg">Share your email we will send you a reset link</p>

              <form [formGroup]="passwordForm" #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" class="">
                <div class="input-group mb-3">
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                  <input type="email" class="form-control" placeholder="Email" formControlName="email"
                    [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">

                  <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.required">Email is required</div>
                  </div>

                  <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.email">Please enter
                      valid email</div>
                  </div>
                  <div *ngIf="(f.email.touched || submitted) && f.email.errors?.maxlength" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.maxlength">Maximum 50 characters are allowed
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12">

                    <button type="submit" class="btn btn-primary btn-block signin">RESET</button>
                  </div>
                  <div class="col-12">
                    <button type="button" class="btn btn-block cancel" (click)="cancel()">CANCEL</button>
                  </div>
                  <!-- /.col -->
                </div>
              </form>

              <!-- <p class="mt-3 mb-1">
                          <a href="/login">Login</a>
                        </p> -->
              <!-- <p class="mb-0">
                          <a href="register.html" class="text-center">Register a new membership</a>
                        </p> -->
            </div>
            <div class="card-body login-card-body" *ngIf="passwordreset==false">
              <p class="login-box-msg">Please check your email for the reset password link</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>