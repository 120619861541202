import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ["./site-header.component.css"],
})
export class SiteHeaderComponent implements OnInit {
  routeParam: any;
  editDetails=false;
  constructor(public router: Router) { }
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  role = localStorage.getItem(`${environment.appName}` + '_role')
  ngOnInit() {
    this.routeParam = this.router.url;
    console.log(this.routeParam);

    var col=this.routeParam.split("/")
    console.log(col);
    var col1=col[1].split("?")
    console.log(col1[0]);
    if(col1[0] == "editDetails")
    {
      this.editDetails=true;
    }else
    {
      this.editDetails=false;
    }

    
  }

}