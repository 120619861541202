import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { formatDate } from "@angular/common";

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) { }

  save1(
    ItemName: string,
    Place: string,
    Category: string,
    Category1: string,
    WalletNumber: string,
    Date: string,
    NumberOfPhotos: string,
    Type: string,
    Image: File,
    Price: any
  ) {
    const formData: FormData = new FormData();
    if (Image) {
      let fileToUpload = <File>Image;
      let fileExtension: string = fileToUpload.name
        .split("?")[0]
        .split(".")
        .pop();
      formData.append("file", Image, WalletNumber + "." + fileExtension);
    }

    formData.append("walletNumber", WalletNumber);
    formData.append("itemName", ItemName);
    formData.append("date", Date);
    formData.append("place", Place);
    formData.append("numberOfDocs", NumberOfPhotos);
    formData.append("category", Category1);
    formData.append("main_cat_type", Category);
    formData.append("sub_cat_type", Type);
    formData.append("price", Price);

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/addsingledoc`,
        formData
      )
      .pipe(
        map((user) => {
          console.log("formData", formData);
          console.log("user", user);
          return user;
        })
      );
  }

  save2(
    ItemName: string,
    recordingplace: string,
    Category: string,
    Category1: string,
    video_no: string,
    recordingdate: string,
    NumberOfPhotos: string,
    Image: File,
    Price: any,
    performer_informer_collection: string
  ) {
    const formData: FormData = new FormData();
    if (Image) {
      let fileToUpload = <File>Image;
      let fileExtension: string = fileToUpload.name
        .split("?")[0]
        .split(".")
        .pop();
      formData.append("file", Image, video_no + "." + fileExtension);
    }

    formData.append("video_no", video_no);
    formData.append("itemName", ItemName);
    formData.append("recordingdate", recordingdate);
    formData.append("recordingplace", recordingplace);
    formData.append("numberOfDocs", NumberOfPhotos);
    formData.append("category", Category1);
    formData.append("main_cat_type", Category);
    // formData.append('sub_cat_type', Type);
    formData.append("price", Price);
    formData.append(
      "performer_informer_collection",
      performer_informer_collection
    );

    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/addsingledoc`,
        formData
      )
      .pipe(
        map((user) => {
          console.log("formData", formData);
          console.log("user", user);
          return user;
        })
      );
  }

  audiosave(
    itemName: string,
    category: string,
    audio_no: string,
    NumberOfAudios: string,
    performer_informer_collection: string,
    recordingplace: string,
    recordingdate: string,
    Image: File,
    Price: any
  ) {
    const formData: FormData = new FormData();
    let fileToUpload = <File>Image;
    let fileExtension: string = fileToUpload.name
      .split("?")[0]
      .split(".")
      .pop();
    formData.append("file", Image, audio_no + "." + fileExtension);
    formData.append("audio_no", audio_no);
    formData.append("itemName", itemName);
    formData.append("recordingdate", recordingdate);
    formData.append("recordingplace", recordingplace);
    formData.append("numberOfDocs", NumberOfAudios);
    formData.append("category", category);
    formData.append(
      "performer_informer_collection",
      performer_informer_collection
    );
    formData.append("main_cat_type", "audio");

    formData.append("price", Price);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/addsingledoc`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  save(
    ItemName: string,
    Place: string,
    Category: string,
    WalletNumber: string,
    Date: string,
    NumberOfPhotos: string,
    Type: string,
    Image: File
  ) {
    const formData: FormData = new FormData();
    formData.append("file", Image);
    formData.append("walletNumber", WalletNumber);
    formData.append("category", Type);
    formData.append("date", Date);
    formData.append("itemName", ItemName);
    formData.append("place", Place);
    formData.append("numberOfPhotos", NumberOfPhotos);
    formData.append("type", Category);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/addsingledoc`,
        formData
      )
      .pipe(
        map((user) => {
          console.log("formData", formData);
          console.log("user", user);
          return user;
        })
      );
  }

  update(
    id: any,
    ItemName: string,
    Place: string,
    Category: string,
    Category1: string,
    WalletNumber: string,
    Date: string,
    performer_informer_collection: string,
    NumberOfPhotos: string,
    Price: any,
    Image: File,
    // wallet_number_type: any,
    mediatype: any,
    sub_cat_type: any
  ) {
    const formData: FormData = new FormData();
    if (Image && Image.name) {
      formData.append("file", Image, Image.name);
    }
    formData.append("walletNumber", WalletNumber);
    formData.append("itemName", ItemName);
    formData.append("date", Date);
    formData.append(
      "performer_informer_collection",
      performer_informer_collection ? performer_informer_collection : ""
    );
    formData.append("place", Place);
    formData.append("numberOfDocs", NumberOfPhotos);
    formData.append("category", Category1);
    formData.append("main_cat_type", Category);
    formData.append("mediatype", mediatype);
    // formData.append('wallet_number_type', wallet_number_type);
    formData.append("price", Price);
    formData.append("sub_cat_type", sub_cat_type);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/update/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  update1(
    id: any,
    ItemName: string,
    Place: string,
    Category: string,
    Category1: string,
    audio_no: any,
    performer_informer_collection: string,
    Date: string,
    price: any,
    Image: File,
    mediatype: any,
    sub_cat_type: any,
    numberOfDocs: any
  ) {
    const formData: FormData = new FormData();
    if (Image && Image.name) {
      formData.append("file", Image, Image.name);
    }
    formData.append(
      "performer_informer_collection",
      performer_informer_collection
    );
    formData.append("itemName", ItemName);
    formData.append("recordingdate", Date);
    formData.append("audio_no", audio_no);
    formData.append("recordingplace", Place);
    formData.append("category", Category1);
    formData.append("main_cat_type", Category);
    formData.append("mediatype", mediatype);
    formData.append("numberOfDocs", numberOfDocs);
    formData.append("price", price);
    formData.append("sub_cat_type", sub_cat_type);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/admin/update/` + id,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  requestToPassword(email: string) {
    const formData: FormData = new FormData();
    formData.append("email", email);
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/forgot-password`,
        formData
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
  excelsheet(type: string): Observable<any> {
    const file = this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/admin/download-sample-excel?media_type=${type}`, { responseType: 'blob' as 'json' })
      .pipe(map(user => {
        return user;
      }));
    return file;
}
  //     requestToPassword(email: string){
  //       const formData: FormData = new FormData();
  //       formData.append('email', email);
  //       return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/forgot-password`, formData).pipe(map(user => {
  //         return user;
  //     }));

  //     }

  // updatePassword(password:string,random: string){
  //   const formData: FormData = new FormData();
  //   formData.append('random', random);
  //   formData.append('password', password);
  //   return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/verify-forgot`, formData).pipe(map(user => {
  //         return user;
  //     }));
  // }
}
