import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { SettingsService, OrderService } from "../_services";
import { ToastrService } from "ngx-toastr";
import { DatePipe, formatDate } from "@angular/common";
import { environment } from "../../environments/environment";
import { ThrowStmt } from "@angular/compiler";

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: "app-settings",
  templateUrl: "./edit-details.component.html",
  styleUrls: ["./edit-details.component.css"],
})
export class editDetailsComponent implements OnInit, AfterViewInit {
  media_domain = `${environment.media_domain}`;

  imagePath: any;
  audiopath: any;
  settingForm: FormGroup;
  settingForm1: FormGroup;
  currentDate: any;
  loading = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  routeParam: any;
  seacrhtxt = "";
  last_page: any;
  fileToUpload2: any;
  image1: any;
  audio: any;

  to: any;
  total: any;
  fileToUpload1: any;
  file: File;

  curPage = 1;
  orders$: any[];
  product_image: any[];
  fileToUpload: File;
  from: any;
  pro: any;
  duration: 0;
  id: any;
  category: any;
  imgsrc: any;
  limit = 10;
  cat_type: any;
  imageChangedEvent1: any;
  submitted = false;
  isResetFlag: boolean = false;
  imageError: string;
  isimageaved: boolean;
  cardImageBase64: string;
  audioSelect = false;

  constructor(
    private setting: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    // this.cat_type = "image";
    this.img_add = true;

    this.currentDate = new Date();

    this.settingForm1 = this.formBuilder.group({
      ItemName: ["", [Validators.required]],
      Place: ["", [Validators.required]],
      Category1: ["", [Validators.required]],
      Date: ["", Validators.required],
      performer_informer_collection: ["", Validators.required],
      type: ["", Validators.required],
      audio_no: ["", Validators.required],
      Price: ["", Validators.required],
      mediatype: ["", Validators.required],
      numberOfDocs: ["", Validators.required],
      // performer_informer_collection:["",Validators.required],
    });
    // this.selecttype(this.cat_type);
    this.route.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.category = params["category"];
      if (this.category == "image") {
        this.audioSelect = false;
        this.settingForm = this.formBuilder.group({
          id: [""],
          ItemName: ["", [Validators.required]],
          Place: ["", [Validators.required]],
          Category: ["image", [Validators.required]],
          Category1: ["", [Validators.required]],
          WalletNumber: ["", Validators.required],
          Date: ["", Validators.required],
          NumberOfPhotos: ["", Validators.required],
          type: ["", Validators.required],
          Price: ["", Validators.required],
          performer_informer_collection: [""],
          mediatype: ["", Validators.required],
          //  video_no: ["", Validators.required],
        });
      }
      if (this.category == "video") {
        this.audioSelect = false;
        this.settingForm = this.formBuilder.group({
          id: [""],
          ItemName: ["", [Validators.required]],
          Place: ["", [Validators.required]],
          Category: ["video", [Validators.required]],
          Category1: ["", [Validators.required]],
          Date: ["", Validators.required],
          type: ["", Validators.required],
          Price: ["", Validators.required],
          performer_informer_collection: ["", [Validators.required]],
          mediatype: ["", Validators.required],
          video_no: ["", Validators.required],
          NumberOfPhotos: ["", Validators.required],
        });
      }
      if (this.cat_type == "audio") {
        this.audioSelect = true;
        this.settingForm1 = this.formBuilder.group({
          ItemName: ["", [Validators.required]],
          Place: ["", [Validators.required]],
          Category1: ["", [Validators.required]],
          Date: ["", Validators.required],
          performer_informer_collection: ["", Validators.required],
          type: ["", Validators.required],
          audio_no: ["", Validators.required],
          Price: ["", Validators.required],
          mediatype: ["", Validators.required],
          numberOfDocs: ["", Validators.required],
        });
      }

      if (this.id) {
        this.order.getsingledoc(this.id, this.category).subscribe((data) => {
          this.cat_type = data.data.mediatype;
          var url = data.data.url;
          this.imagePath = this.media_domain + data.data.url;

          var $audio = $("#file1");
          $audio.attr("src", this.imagePath);
          $("#fileinput1").on("change", function (e) {
            var target = e.currentTarget;
            this.fileToUpload1 = target.files[0];
            var reader = new FileReader();
            if (target.files && this.fileToUpload1) {
              var reader = new FileReader();
              reader.onload = function (e) {
                $audio.attr("src", reader.result);
              };
              reader.readAsDataURL(this.fileToUpload1);
            }
          });

          if (data.data.mediatype == "image") {
            this.img_add = true;
            this.video_add = false;
            this.audio_add = false;
          }

          if (data.data.mediatype == "audio") {
            this.img_add = false;
            this.video_add = false;
            this.audio_add = true;

            this.settingForm1.patchValue({
              id: data.data._id,
              ItemName: data.data.itemName,
              Place: data.data.recordingplace,
              Category1: data.data.category,
              Date: data.data.recordingdate,
              type: data.data.type,
              Price: data.data.price,
              audio_no: data.data.audio_no,
              mediatype: data.data.mediatype,
              performer_informer_collection:
                data.data.performer_informer_collection,
              video_no: data.data.video_no,
              numberOfDocs: data.data.numberOfDocs,
            });
          }

          if (data.data.mediatype == "video") {
            this.img_add = false;
            this.video_add = true;
            this.audio_add = false;
          }

          if (data.data.url) {
            this.imagePath = this.media_domain + data.data.url;
            var $audio = $("#file1");
            $audio.attr("src", this.imagePath);
          }

          this.audiopath = data.data.url;

          this.settingForm &&
            this.settingForm.patchValue({
              id: data.data._id,
              ItemName: data.data.itemName,
              Place: data.data.place
                ? data.data.place
                : data.data.recordingplace,
              Category: data.data.mediatype,
              Category1: data.data.category,
              WalletNumber: data.data.walletNumber,
              Date: data.data.date ? data.data.date : data.data.recordingdate,
              NumberOfPhotos: data.data.numberOfDocs,
              type: data.data.type,
              Price: data.data.price,
              performer_informer_collection:
                data.data.performer_informer_collection,
              mediatype: data.data.mediatype,
              video_no: data.data.video_no,
              numberOfPhotos: data.data.numberOfPhotos,
            });

          // this.fileToUpload1=data.data.url;
        });
      }
    });
  }

  playVideo(event) {
    event.toElement.play();
  }

  get f(): any {
    return this.settingForm.controls;
  }
  get f1(): any {
    return this.settingForm1.controls;
  }

  public onCancel(): void {
    this.router.navigate(["/home"]);
  }

  selecttype(cat_type) {
    console.log(cat_type);
    if (cat_type == "image") {
      this.audioSelect = false;
      this.settingForm = this.formBuilder.group({
        id: [""],
        ItemName: ["", [Validators.required]],
        Place: ["", [Validators.required]],
        Category: ["image", [Validators.required]],
        Category1: ["", [Validators.required]],
        WalletNumber: ["", Validators.required],
        Date: ["", Validators.required],
        NumberOfPhotos: ["", Validators.required],
        type: ["", Validators.required],
        Price: ["", Validators.required],
        // performer_informer_collection: [""],
        mediatype: ["", Validators.required],
        //  video_no: ["", Validators.required],
      });
    } else if (cat_type == "audio") {
      this.audioSelect = true;
      this.settingForm1 = this.formBuilder.group({
        ItemName: ["", [Validators.required]],
        Place: ["", [Validators.required]],
        Category1: ["", [Validators.required]],
        Date: ["", Validators.required],
        performer_informer_collection: ["", Validators.required],
        type: ["", Validators.required],
        audio_no: ["", Validators.required],
        Price: ["", Validators.required],
        mediatype: ["", Validators.required],
        numberOfDocs: ["", Validators.required],
      });
    }
  }

  onBack() {
    this.router.navigate(["/ViewRRC"], {
      queryParams: { type: this.cat_type },
    });
  }

  uploadImage() {
    $("#fileinput1").trigger("click");
  }

  handleFileInput1(event: any, files: FileList) {
    console.log(files[0]);
    var $audio = $("#file1");
    if (this.imagePath) {
      $audio.attr("src", this.imagePath);
    }

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (evt: any) => {
        $audio.attr("src", evt.target.result);
        this.audio = null;
        this.audio = evt.target.result;
        if (!this.imagePath) {
          this.imagePath = evt.target.result;
        }
      };
    }
    this.fileToUpload = event.target.files.item(0);
    reader && reader.readAsDataURL(event.target.files[0]);
  }

  handleFileInput2(event: any, files: FileList) {
    this.imageChangedEvent1 = event;
    var that = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = new Image();

      if (that.cat_type == "image") {
        that.imagePath = reader.result;
      }
      if (that.cat_type == "video") {
        that.imagePath = reader.result;
      }
      if (that.cat_type == "audio") {
        that.imagePath = reader.result;
      }
    };

    that.fileToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
  }

  triggerFile1() {
    let element: HTMLElement = document.querySelector(
      'input[id="image1"]'
    ) as HTMLElement;
    element.click();
  }

  add_bulk(e) {}

  category_filter(val) {
    if (val == "image") {
      this.img_add = true;
      this.video_add = false;
      this.audio_add = false;
    } else if (val == "video") {
      this.img_add = false;
      this.video_add = true;
      this.audio_add = false;
    } else {
      this.img_add = false;
      this.video_add = false;
      this.audio_add = true;
    }
  }

  onSubmit(form, formWrap) {
    console.log(this.settingForm);
    console.log(this.cat_type);
    this.submitted = true;
    if (this.cat_type == "image") {
      if (this.settingForm.invalid) {
        this.toastr.error("Please enter all fields");
        return;
      }
    }
    // else if (this.cat_type == "video") {
    //   if (this.settingForm.invalid) {
    //     return;
    //   }
    // }

    document.body.classList.add("jw-modal-open");
    // this.loading = true;
    if (this.cat_type == "image") {
      this.setting
        .update(
          this.f.id.value,
          this.f.ItemName.value,
          this.f.Place.value,
          this.f.Category.value ? this.f.Category.value : "image",
          this.f.Category1.value,
          this.f.WalletNumber.value,
          this.f.Date.value,
          this.f.performer_informer_collection.value,
          this.f.NumberOfPhotos.value,
          this.f.Price.value,
          this.fileToUpload,
          // this.f.wallet_number_type.value ? this.f.wallet_number_type.value : '',
          this.f.mediatype.value,
          this.f.type.value
        )

        .subscribe(
          (data) => {
            if (data.status.code == 0) {
              this.submitted = false;
              this.isResetFlag = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              formWrap.reset();
              this.imagePath = "";
              this.toastr.success(data.status.message);
              // location.reload();
              this.router.navigate(["/ViewRRC"], {
                queryParams: { type: this.cat_type },
              });
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    } else if (this.cat_type == "video") {
      this.setting
        .update(
          this.f.id.value,
          this.f.ItemName.value,
          this.f.Place.value,
          this.f.Category.value ? this.f.Category.value : "video",
          this.f.Category1.value,
          this.f.video_no.value,
          this.f.Date.value,
          this.f.performer_informer_collection.value,
          // this.f.NumberOfPhotos.value,
          // '',
          this.f.NumberOfPhotos.value,

          this.f.Price.value,
          this.fileToUpload,
          // this.f.wallet_number_type.value ? this.f.wallet_number_type.value : '',
          this.f.mediatype.value,
          this.f.type.value
        )

        .subscribe(
          (data) => {
            if (data.status.code == 0) {
              this.submitted = false;
              this.isResetFlag = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              formWrap.reset();
              this.imagePath = "";
              this.toastr.success(data.status.message);
              this.router.navigate(["/ViewRRC"], {
                queryParams: { type: this.cat_type },
              });
              // location.reload();
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    } else {
      this.setting
        .update1(
          this.id,
          this.f1.ItemName.value,
          this.f1.Place.value,
          "audio",
          this.f1.Category1.value,
          this.f1.audio_no.value,
          this.f1.performer_informer_collection.value,
          this.f1.Date.value,
          this.f1.Price.value,
          this.fileToUpload,
          this.f1.mediatype.value,
          this.f1.type.value,
          this.f1.numberOfDocs.value
        )
        .subscribe(
          (data) => {
            if (data.status.code == 0) {
              this.submitted = false;
              this.isResetFlag = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              formWrap.reset();
              this.imagePath = "";
              this.toastr.success(data.status.message);
              this.router.navigate(["/ViewRRC"], {
                queryParams: { type: this.cat_type },
              });
              //    location.reload();
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    }
  }

  ngAfterViewInit() {
    // var $audio2 = $('#fileupload');
    // $('#fileinput1').on('change', function(e) {
    //   var target = e.currentTarget;
    //    this.fileToUpload1 = target.files[0];
    //   var reader = new FileReader();
    //   console.log(this.fileToUpload1.size)
    //    if(this.fileToUpload1.size >=  4*1024*1024){
    //        return false;
    //    }
    //     if(target.files && this.fileToUpload1)
    //     {
    //         var reader = new FileReader();
    //         reader.onload = function (e) {
    //             $audio2.attr('src', reader.result);
    //         }
    //         reader.readAsDataURL(this.fileToUpload1);
    //     }
    //   });
  }

  getdate(e) {
    console.log(e);
  }
}
