import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
// import { AnyARecord } from 'dns';

@Injectable()
export class OrderService {
    constructor(private http: HttpClient) { }
    getOrder(search: any, page: any, limit: any, status: any, provider: any, from_date: any, to_date: any, category: any): Observable<any> {
        if (!status) {
            status = -1;
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs?search=` + search + '&page=' + page + '&limit=' + limit + '&status=' + status + '&provider=' + provider + '&from_date=' + from_date + '&to_date=' + to_date + `&category=` + category).pipe(map(user => { return user; }));
    }

    getDetails(search: any, page: any, category: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get_adminlist?search=` + search + '&page=' + page + `&mediatype=` + category).pipe(map(user => { return user; }));
    }

    userInformation(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/user?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }

    getManageOrder(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manageorder?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }

    getManageOrderpend(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manage_orderpending?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }

    getManageOrderreject(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manage_orderreject?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }

    getManageOrderrefund(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manage_orderrefund?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }


    getManageOrderaccept(search: any, page: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manage_orderaccept?search=` + search + '&page=' + page ).pipe(map(user => { return user; }));
    }


    getImageCount() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/totalcount`).pipe(map(user => user));
    }
    
    getsingledoc(id: any, category: any) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/getData/` + id + '?category=' + category).pipe(map(user => user));
    }


    bulk_upload(upload: File, bulk_type: any) {
        const formData: FormData = new FormData();
        formData.append('file', upload, upload.name);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/postsingledoc?category=` + bulk_type, formData).pipe(map(data => {
            return data;
        }));
    }

    bulk_upload_file(upload: any, bulk_type: any) {
        const formData: FormData = new FormData();
        // formData.append('file', upload);
        console.log(upload.length);
        
        for (var i = 0; i < upload.length; i++) { 
            formData.append("file", upload[i]);
            console.log(upload[i]);
          }
        formData.append('category',bulk_type);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/upload/excelupload`, formData).pipe(map(data => {
            return data;
        }));
    }

    bulk_upload_img(upload: any, bulk_type: any) {
       
        const formData: FormData = new FormData();
        // if(upload){
        //     formData.append('file', upload);
        // }

        for (var i = 0; i < upload.length; i++) { 
            formData.append("file", upload[i]);
            console.log(upload[i]);
          }
      
      
        formData.append('category',bulk_type);
   
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/bulkDOCupload`, formData).pipe(map(data => {
            return data;
        }));
    }


    getSingleOrder(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs/` + id).pipe(map(user => user));
    }
    getSortRecord(sortby: any, category: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs?sortby=` + sortby + '&category=' + category).pipe(map(user => user));
    }
    getSortRecordasc(category: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs?category=` + category).pipe(map(user => user));
    }
    getCategoryData(search: any, page: any, limit: any, status: any, provider: any, from_date: any, to_date: any, category: any): Observable<any> {

        // return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs?category=` + category).pipe(map(user => user));
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getdocs?search=` + search + '&page=' + page + '&limit=' + limit + '&status=' + status + '&provider=' + provider + '&from_date=' + from_date + '&to_date=' + to_date + `&category=` + category).pipe(map(user => { return user; }));

    }








    getProvider(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get_provider`).pipe(map(user => user));

    }


    getDriverlist(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-driverstatus`).pipe(map(user => user));

    }

    assign_driver(id: any, driverId: string, status: any) {
        const formData: FormData = new FormData();
        formData.append('driverId', driverId);
        formData.append('status', status);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/assing_driver/` + id, formData).pipe(map(data => {
            return data;
        }));

    }

    status_update(id: string, status: any) {
        const formData: FormData = new FormData();


        formData.append('status', status);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/order/update_status/` + id, formData).pipe(map(data => {
            return data;
        }));

    }

    charges_update(id: any, driver_charge: any, provider_charge: any) {
        const formData: FormData = new FormData();
        formData.append('driver_charge', driver_charge);
        formData.append('provider_charge', provider_charge);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/order/add_charges/` + id, formData).pipe(map(data => {
            return data;
        }))
    }

    //manage orders

    addUrl(id: any, url: any) {
        const formData: FormData = new FormData();
        formData.append('link', url);
        formData.append('order_id', id);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/updatelink`, formData).pipe(map(data => {
            return data;
        }));
    }

    getSingleURL(id) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/managesingleorder?search=`+id).pipe(map(user => user));

    }


    updateOrderStatus(order_id:any,status:any){
        const formData: FormData = new FormData();
        formData.append('order_id', order_id);
        formData.append('status', status);
   
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/updatestatus`, formData).pipe(map(user => {
            return user;
        }));
    }


    //madhura

    getCounts(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/manage_order_stat`).pipe(map(user => user));
    }

}
