<!-- <site-header></site-header> -->
<!-- <div class="top_bar_menu">
  <div class="top_header">
      <div class="row_main">
          <div class="col-sm-1">
              <div class="logo_area">
                  <a href="/home"><img src="assets/images/logo_small.png" class="img-responsive" alt="logo"></a>
              </div>
          </div>
          <div class="col-sm-9">
              <div class="header_list">
                  <ul>
                      <li class="nav-item d-none d-sm-inline-block nav_bgclr">

                          <a [routerLink]="['/home']" class="nav-link active">
                              <p>
                                  Dashboard
                              </p>
                          </a>
                      </li>
                      <li class="nav-item d-none d-sm-inline-block nav_bgclr">
                          <a href="/ViewRRC" [routerLink]="['/ViewRRC']" class="nav-link">
                              <p>
                                  View Information
                              </p>
                          </a>
                      </li>
                      <li class="nav-item">
                          <a [routerLink]="['/AddRRC']" class="nav-link ">
                              <p>
                                  Add Information
                              </p>
                          </a>
                      </li>

                      <li class="nav-item">
                          <a [routerLink]="['/bulk-upload']" class="nav-link">
                              <p>
                                  Bulk Upload
                              </p>
                          </a>
                      </li>
                  </ul>
              </div>


          </div>
          <div class="col-sm-2">
              <div class="logout_main">
                  <a href="/login" class="nav-link">
                      Logout
                      <i class="fa-solid fa-power-off"></i>


                  </a>
              </div>

          </div>

      </div>
  </div>


</div> -->
<site-header></site-header>
<div class="home_main flt">
  <div class="container">
    <div class="inner_home flt">
      <div class="count_main flex flt">
        <div class="image_count">
          <a (click)="order(3)">
            <div class="info-box">
              <span class="info-box-icon cmn-icon bg-success"><img src="assets/images/images.png" /></span>

              <div class="info-box-content">
                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                <!-- <span class="info-box-text">Image</span> -->
                Images
              </div>

            </div>
          </a>
        </div>
        <div class="video_count flt">
          <a (click)="order(2)">
            <div class="info-box">
              <span class="info-box-icon cmn-icon bg-success"><img src="assets/images/videos.png" /></span>

              <div class="info-box-content">
                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                <!-- <span class="info-box-text">Videos</span> -->
                Videos
              </div>

            </div>
          </a>
        </div>
        <div class="audio_count flt">
          <a (click)="order(1)">
            <div class="info-box">
              <span class="info-box-icon cmn-icon bg-success"><img src="assets/images/audios.png" /></span>

              <div class="info-box-content flt">
                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                <!-- <span class="info-box-text"></span> -->
                Audios
              </div>

            </div>
          </a>
        </div>
     
      

      </div>

    </div>


    <!-- <div class="count_head flt">
      Total Set Of Assets : <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span>
    </div> -->

    <div class="element_collection flt">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="image_collection flt">
                <div class="collection_head flt">
                  Images
                  <span><img src="assets/images/images.png" /></span>
                </div>
                <div class="col_body_main flt">
                  <!-- <div class="collection_body flt">
                    Total Categories
                    <span>3</span>
                  </div> -->
                  <div class="collection_body flt" *ngIf="orderscount && orderscount.Imagefilescount ? orderscount.Imagefilescount:'0'">
                    Total Image Files
                    <span>{{orderscount && orderscount.Imagefilescount ? orderscount.Imagefilescount:'0'}}</span>
                  </div>
                  <!-- <div class="collection_body flt" *ngIf="orderscount && orderscount.Color ? orderscount.Color:'0'">
                    Total Image files with photo
                    <span>{{orderscount && orderscount.Color}}</span>
                  </div> -->
                  <div class="collection_body flt"
                    *ngIf="orderscount && orderscount.blackandwhite?orderscount.blackandwhite:'0'">
                    Total Black And White Images
                    <span>{{orderscount && orderscount.blackandwhite?orderscount.blackandwhite:'0'}}</span>
                  </div>
                  <div class="collection_body flt" *ngIf="orderscount && orderscount.color?orderscount.color:'0'">
                    Total Color Images
                    <span>{{orderscount && orderscount.color?orderscount.color:'0'}}</span>
                  </div>
                
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="vdo_collection flt">
                <div class="collection_head flt">
                  Videos
                  <span><img src="assets/images/videos.png" /></span>
                </div>
                <div class="col_body_main flt">
                  <!-- <div class="collection_body flt">
                    Total Categories
                    <span>0</span>
                  </div> -->
                  <div class="collection_body flt" *ngIf="orderscount && orderscount.videofilescount ? orderscount.videofilescount:'0'">
                    Total Video Files
                    <span>{{orderscount && orderscount.videofilescount ? orderscount.videofilescount:'0'}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mp_collection flt">
                <div class="collection_head flt">
                  Audios
                  <span><img src="assets/images/audios.png" /></span>
                </div>
                <div class="col_body_main flt">
                  <!-- <div class="collection_body flt">
                    Total Categories
                    <span>0</span>
                  </div> -->

                  <div class="collection_body flt" *ngIf="orderscount && orderscount.audiofilescount ? orderscount.audiofilescount:'0'">
                    Total Audio Files
                    <span>{{orderscount && orderscount.audiofilescount ? orderscount.audiofilescount:'0'}}</span>
                  </div>
                </div>
              </div>

            </div>
          
         

          </div>
        </div>
      </div>

      <!-- <div class="mp_collection">
        <div class="collection_head">
          Audios
        </div>
        <div class="collection_body">

        </div>
      </div>
      <div class="vdo_collection">
        <div class="collection_head">
          Videos
        </div>
        <div class="collection_body">

        </div>
      </div>
      <div class="image_collection">
        <div class="collection_head">
          Image
        </div>
        <div class="collection_body">

        </div>
      </div> -->
    </div>

  </div>



</div>

<!-- <div class="wrapper">
  <div class="content-wrapper">



    <section class="content dashboard_box">
      <div class="container-fluid">

        <div class="row">
     
          <div class="col-md-3 col-sm-6 col-12">
            <a (click)="order($event)">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><i class="fa-solid fa-folder-music"></i></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span>
                  <span class="info-box-text">Total Audios</span>
                </div>

              </div>
            </a>
          </div>
        
          <div class="col-md-3 col-sm-6 col-12">
            <a (click)="order($event)">
              <div class="info-box">

                <span class="info-box-icon cmn-icon bg-success"><img src="../../assets/images/admin.png" /></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{delivered?delivered:'0'}}</span>
                  <span class="info-box-text">Total Videos</span>
                </div>

              </div>
            </a>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <a (click)="userdata($event)">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><img src="../../assets/images/user.png" /></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{user$ ? user$:'0'}}</span>
                  <span class="info-box-text">Total Image</span>
                </div>

              </div>
            </a>
          </div>

          <div class="col-md-3 col-sm-6 col-12">
            <a (click)="driverdata($event)">
              <div class="info-box">
                <span class="info-box-icon cmn-icon bg-success"><img src="../../assets/images/driver.png" /></span>

                <div class="info-box-content">
                  <span class="info-box-number txt-clr-dashboard">{{driver$?driver$:'0'}}</span>
                  <span class="info-box-text">Total Assets</span>
                </div>

              </div>
            </a>
          </div>



        </div>

      </div>
    </section> -->