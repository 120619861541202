import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService } from '../_services';
declare var jquery: any;
declare var $: any;
@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    // tslint:disable-next-line: variable-name
    mobile_no = '';
    message = '';
    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required]
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    onSubmit() {

        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {

            return;
        }

        document.body.classList.add('jw-modal-open');
        this.loading = true;

        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .subscribe(
                data => {
                    console.log(data)

                    if (data.status.code == 1) {
                        //
                        this.toastr.error(data.status.message);
                        document.body.classList.remove('jw-modal-open');
                        this.loading = false;
                    } else {
                        location.href = this.returnUrl;

                    }
                    // this.router.navigate([this.returnUrl]);
                },
                error => {

                    this.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    this.loading = false;
                });
    }
}
