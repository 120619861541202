import { Component, OnInit, OnDestroy, AfterViewInit,ElementRef,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { SettingsService,OrderService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { environment } from '../../environments/environment';



declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.css']
})
export class VideouploadComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

  createForm: FormGroup;
  bulkform:FormGroup;
  videoChangedEvent1: any;
  cat_type = "video";
  video1: any;
  fileToUpload: File;
  currentDate: any;
  videopath:any;
  view_type= 'image';


  submitted = false;
  submitted1 = false;
  loading=false;

  filename: any;
  public animation: boolean = true;
  public multiple: boolean = true;

  private filesControl = new FormControl(null, Validators.required);

  public excelForm = new FormGroup({
      files: this.filesControl
  });

  private filesControlvideo = new FormControl(null, Validators.required);

  public videoForm = new FormGroup({
      files: this.filesControlvideo
  });
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private setting: SettingsService,
    private route: ActivatedRoute,
    private order: OrderService,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.createForm = this.formBuilder.group({
      ItemName: ["", [Validators.required, Validators.maxLength(25)]],
      recordingplace: ["", [Validators.required, Validators.maxLength(25)]],
      Category: ["video", [Validators.required, Validators.maxLength(25)]],
      Category1: ["", [Validators.required, Validators.maxLength(25)]],
      video_no: ["",[Validators.required]],
      recordingdate: ["", [Validators.required]],
      NumberOfPhotos: ["", [Validators.required]],
      PerformerInformerCollection:["",[Validators.required]],
      // type: ["", Validators.required],
      Photos: ["",[ Validators.required]],
      Price: ["",[Validators.required]],
    });
 
 

  }


  public toggleStatus(): void {
    this.filesControl.disabled ? this.filesControl.enable() : this.filesControl.disable();
}

public toggleMultiple() {
    this.multiple = !this.multiple;
}

public clear(): void {
    this.filesControl.setValue([]);
}
  get f(): any {
    return this.createForm.controls;
  }

  get b(): any {
    return this.excelForm.controls;
  }

  get i(): any {
    return this.videoForm.controls;
  }

  handleFileInput1(event: any, files: FileList) {
    // console.log("event", event);
    // console.log("FileList", FileList);
    this.videoChangedEvent1 = event;

    var that = this;
    var reader = new FileReader();
    if (!event.target.files[0].type.match("video.*")) {
      that.toastr.error('Choose only videos');
      return false;
    }
    reader.onload = function (e) {

      // var img = new Image();
      // if (that.cat_type == "image") {
      //   img.onload = function () {
      //     that.image1 = null;
      //     that.image1 = img.src;
      //     that.fileToUpload = files.item(0);
      //     console.log("that.fileToUpload", that.fileToUpload);
      //   };

      // }

      // img.src = reader.result as string;
      // that.imgsrc = img.src;
      that.fileToUpload = files.item(0);
      // alert(that.imgsrc)
    };
    reader.readAsDataURL(files[0]);
  }

  onSubmit(form, formWrap) {
    this.submitted = true;
   
      if (this.createForm.invalid) {
        return;
      }
      
      alertify.confirm('Are you sure want to upload this record?', () => {
        

        this.loading=true;

          this.setting.save2(
          this.f.ItemName.value,
          this.f.recordingplace.value,
          this.f.Category.value ? this.f.Category.value : "video",
          this.f.Category1.value,
          this.f.video_no.value,
          this.f.recordingdate.value,
          this.f.NumberOfPhotos.value,
          // this.f.type.value,
          this.fileToUpload,
          this.f.Price.value,
          this.f.PerformerInformerCollection.value,
        )
        .subscribe(
          (data) => {
            if (data.status.code == 0) {
              this.submitted = false;
              this.loading = false;
              formWrap.reset();
              this.ngOnInit();
              this.toastr.success(data.status.message);
            } else {
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            this.loading = false;
          }
        );


      }, function () {
    
      });
    
  }

  onBack() {
    this.router.navigate(['/ViewRRC'],{ queryParams: { type : this.cat_type } });
  }

  handleBulkFile(event: any, files: any) {  
    console.log(files);
    var that = this;
    // alertify.confirm('Are you sure want to upload this record?', () => {
    //   that.loading = true;

    //   that.order.bulk_upload_file(files, 'video').subscribe(data => {
    //       if (data.status.code == 0) {
          
    //         that.toastr.success(data.status.message);
    //       } else {
    //         that.toastr.error(data.status.message)
    //       }
    //       that.loading = false;
    //     })
      
    // }, function () {
    
    // });

  }

 

 


  bulkSubmit() {
    const that = this;
    that.submitted1 = true;
 
    if (that.filesControl.invalid) {
      that.toastr.error("Please upload a Video Excel File");
      return;
    }

  

    console.error(that.filesControl.value);
    alertify.confirm('Are you sure want to upload this record?', () => {
      this.loading=true;
    that.order.bulk_upload_file(that.filesControl.value, 'video').subscribe(data => {
      if (data.status.code == 0) {
  
        that.toastr.success(data.status.message);
        that.filesControl.setValue([]);
        that.submitted1 = false;
        that.loading = false;
       
        that.ngOnInit();
      } else {
        that.toastr.error(data.status.message)
        that.loading = false;
        that.filesControl.setValue([]);
        that.ngOnInit();
        that.submitted1 = false;

      }

    })
  }, function () {
    that.toastr.error('Upload action cancelled');
    that.loading = false;
  });


  }

 

  bulkvideoSubmit() {
    const that = this;
    that.submitted1 = true;
    
    if (that.filesControlvideo.invalid) {

      that.toastr.error("Please upload a Video File");

      return;
    }

  

    console.error(that.filesControlvideo.value);
    alertify.confirm('Are you sure want to upload this record?', () => {
      that.loading=true;
    that.order.bulk_upload_img(that.filesControlvideo.value, 'video').subscribe(data => {
      if (data.status.code == 0) {
  
        that.toastr.success(data.status.message);
        that.filesControlvideo.setValue([]);
        that.submitted1 = false;
        that.loading = false;
        that.ngOnInit();
      } else {
        that.toastr.error(data.status.message)
        that.loading = false;
        that.submitted1 = false;
        that.filesControl.setValue([]);
        that.ngOnInit();
      }
 
    })
  }, function () {
    that.toastr.error('Upload action cancelled');
    that.loading = false;
  });


  }
 
  excel(){
    this.setting.excelsheet('video').subscribe(fileData => {

      const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download","Bulk Video upload sample" );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  }



}
