import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService, OrderService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { identifierModuleUrl } from '@angular/compiler';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.css']
})
export class userInformationComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

  orders$: any[];
  orderss$: any[];
  loading = false;
  orderId: any;
  curPage = 1;
  perPage = 10; 
  last_page: any;
  status_text = '';
  search_data = '';
  search_status = '';
  view_img: any;
  total: any;
  from: any;
  vallen: any;
  drivers: any;
  bulk_type: any;
  imgpath: any;
  datefrom: any;
  to: any;
  from_date: any;
  to_date: any;
  searchUser: any;
  limit = 10;
  code = 1;
  pro: any;
  items: any;
  routeParam: any;
  provider: any;
  add_product: any;
  val1: any;
  bulk_add = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  cat_type = "images";
  val_event: any;
  users$: any;
  dateto: any;
  sortasc: any;
  sortdecs: any;
  myInputVariable: ElementRef;
  pro$: any;
  current_date: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bulkFile: File = null;
  dtTrigger2: Subject<any> = new Subject();
  page:any
  pageCounter:any
  topage:any

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private home: HomeService,
  ) { }
  type = '1';
  seacrhtxt = '';
  S3link = 'http//rrc-chipsy.s3.ap-south-1.amazonaws.com'




  ngOnInit() {
 
    //  alert(this.getTab);

    this.img_add = true;
    this.bulk_type = "images";
    this.sortasc = false;
    this.sortdecs = true;
    var date = new Date();
    // this.current_date = this.formatDate(date);

      this.order.userInformation('', 1).subscribe(data => {
        
        this.orders$ = data.data.docs;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
        this.dtTrigger.next();

       


      //   this.curPage = data.data.blog.page;
      // this.total= data.data.blog.totalDocs;
      // this.from = data.data.blog.prevPage;
      // this.to = data.data.blog.nextPage;
      // this.last_page = data.data.blog.totalPages;

      // this.limit = data.data.blog.limit;
      // this.page = data.data.blog.page;
      // this.pageCounter = data.data.blog.pagingCounter;
      // this.topage = (this.limit * (this.page - 1)) + this.blog$.length;
      // this.dtTrigger.next();
        
        return false;
        this.dtTrigger.next();
      }, error => {
        console.error(error);
      });

  }

  
  search(e) {

    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.seacrhtxt = e.target.value;

    this.order.userInformation(e.target.value, 1).subscribe(data => {
      if (data.code == 0) {
        this.code = data.code;
      }
      // this.vallen = this.orders$.length;
      // this.orders$ = data && data.result ? data.result : '';
      // this.curPage = data && data.currentPage ? data.currentPage : '';
      // this.total = data && data.result && data.result.length ? data.result.length : '';
      // this.from = data && data.currentPage ? data.currentPage : '';
      // this.to = data && data.totalPages ? data.totalPages : '';
      // this.last_page = data && data.totalPages ? data.totalPages : '';

      this.vallen = data.data.totalPages;
      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
        this.dtTrigger.next();

    })
  }

  pageChange(e) {
    this.order.userInformation(this.seacrhtxt, e).subscribe(data => {
      // this.orders$ = data.result;
      // this.curPage = data.currentPage;
      // this.total = data.result.length;
      // this.from = data.currentPage;
      // this.to = data.totalPages;
      // this.last_page = data.totalPages;

      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
        this.dtTrigger.next();

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  //bulk code
  add_bulk(val) {
    this.bulk_type = val;
    // this.add_product = false;
    this.bulk_add = true;

  }

  refresh() {
    this.order.userInformation('',1).subscribe(data => {
      
      // this.orders$ = data.result;
      // this.curPage = data.currentPage;
      // this.total = data.result.length;
      // this.from = data.currentPage;
      // this.to = data.totalPages;
      // this.last_page = data.totalPages;

      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
        this.dtTrigger.next();

    });
  }

  triggerBulk() {
    let element: HTMLElement = document.querySelector('input[id="bulk"]') as HTMLElement;
    element.click();
  }


  resetBulk() {
    this.myInputVariable.nativeElement.value = "";
  }


  

  viewDet(e, user) {
    if (user.type == "images") {
      this.view_img = "images";
    } else if (user.type == "video") {
      this.view_img = "video";
    } else {
      this.view_img = "audio";
    }
    // alert(this.view_img)
    this.imgpath = this.media_domain + user.image;

    $('#managebenModal').modal('show');

    // this.router.navigate(['/order-details'], { queryParams: { id: user._id } });
  }

  editDet(e, user) {
    // alert(JSON.stringify(user));
    this.router.navigate(['/editDetails'], { queryParams: { id: user._id, category: user.category } });
  }

}
