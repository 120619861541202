import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class RoleService {
    constructor(private http: HttpClient) { }



    saveadmin(name:any,email:any,password:any,roleId:any,profile: File,id_proof: File){
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('roleId', roleId);
   
        if (profile) {
            formData.append('profile',profile, profile.name);
        }
        if (id_proof) {
            formData.append('id_proof',id_proof, id_proof.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/createAdmin`, formData).pipe(map(user => {
            return user;
        }));
    }

    updateadmin(id: any,name:any,email:any,password:any,roleId:any,profile: File,id_proof: File){
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('roleId', roleId);
        if (profile && profile.name) {
            formData.append('profile',profile, profile.name);
        }
        if (id_proof && id_proof.name) {
            formData.append('id_proof',id_proof, id_proof.name);
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/updateAdmin/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    deleteAdmin(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/deleteAdmin/` + id).pipe(map(user => {
            return user;
        }));

    }
    getAdmins(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/getAdmin?search=`+search+'&page='+page).pipe(map(user => user));

    }

    getSingleAdmin(id: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/multipleadmin/getAdmin/` + id).pipe(map(user => user));

    }

    getUsers(search:any,page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/get-user?page=`+ page + '&search=' + search).pipe(map(user => user));

    }




}
