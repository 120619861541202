<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<site-header></site-header>
<div class="wrapper flt">
    <div class="container">

        <div class="content-wrapper ">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <!-- <ol class="breadcrumb">
                                <li class="breadcrumb-item active">video Details</li>
                            </ol> -->
                        </div>
                    </div>
                </div>
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="create_order_main">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#home">Create video details</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#menu1">Bulk video excel details upload </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#menu2">Bulk video files upload</a>
                            </li>
                        </ul>
                        <!-- Tab panes -->
                        <div class="tab-content">
                            <div class="tab-pane active" id="home">
                                <div class="card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title">Create single video details</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">

                                        <div class="input_body flt">
                                            <form [formGroup]="createForm" #myForm="ngForm"
                                                (ngSubmit)="onSubmit(createForm, myForm)" class="">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b flt">
                                                            <label class="form-control-label" for="itemName">Item
                                                                Name</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box" id="itemName"
                                                                formControlName="ItemName" onload="this.value=''"
                                                                placeholder="Item Name" autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.ItemName.touched || submitted) && f.ItemName?.errors }" />
                                                            <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.ItemName.errors?.required">Item Name Is
                                                                    Required</div>
                                                            </div>
                                                            <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.maxlength"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.ItemName.errors?.maxlength">Maximum 25
                                                                    characters
                                                                    are
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label"
                                                                for="Category1">Category</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box"
                                                                id="Category1" formControlName="Category1"
                                                                onload="this.value=''" placeholder="Category"
                                                                autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.Category1.touched || submitted) && f.Category1.errors }" />
                                                            <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.Category1.errors?.required">Category Is
                                                                    Required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.maxlength"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.Category1.errors?.maxlength">Maximum 25
                                                                    characters
                                                                    are
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label"
                                                                for="PerformerInformerCollection">Performer Informer
                                                                Collection</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box"
                                                                id="PerformerInformerCollection"
                                                                formControlName="PerformerInformerCollection"
                                                                onload="this.value=''"
                                                                placeholder="Performer Informer Collection"
                                                                autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors }" />
                                                            <div *ngIf="(f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors?.required"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="f.PerformerInformerCollection.errors?.required">
                                                                    Performer Informer Collection Is Required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.PerformerInformerCollection.touched || submitted) && f.PerformerInformerCollection.errors?.maxlength"
                                                                class="invalid-feedback">
                                                                <div
                                                                    *ngIf="f.PerformerInformerCollection.errors?.maxlength">
                                                                    Maximum 25 characters
                                                                    are
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--  -->
                                                    <!-- <div class="col-sm-4">
                                                                    <div class="example-wrap b">
                                                                        <label class="form-control-label" for="category">Main Category</label>
                                                                       
                                                                        <select id="category" formControlName="Category" placeholder="Category"
                                                                            autocomplete="off"
                                                                            [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }"
                                                                            class="form-controls dp ">
                                                                            <option [selected] value="" disabled>Main Category Type</option>
                                                                            <option value="video">video</option>
                                                                            <option value="video" disabled>Video</option>
                                                                            <option value="audio" disabled>Audio</option>
                                
                                
                                                                        </select>
                                                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.required"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Category.errors?.required">Main Category Is
                                                                                Required</div>
                                                                        </div>
                                                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.maxlength"
                                                                            class="invalid-feedback">
                                                                            <div *ngIf="f.Category.errors?.maxlength">Maximum 25
                                                                                characters
                                                                                are
                                                                                allowed
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->

                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label" for="walletNumber">Video
                                                                Number</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box"
                                                                id="walletNumber" formControlName="video_no"
                                                                placeholder="Video Number" autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.video_no.touched || submitted) && f.video_no.errors }" />
                                                            <div *ngIf="(f.video_no.touched || submitted) && f.video_no.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.video_no.errors?.required">Video
                                                                    Number
                                                                    Is Required</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label" for="place">Recording
                                                                Place</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box" id="place"
                                                                formControlName="recordingplace" onload="this.value=''"
                                                                placeholder="Place" autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.recordingplace.touched || submitted) && f.recordingplace.errors }" />
                                                            <div *ngIf="(f.recordingplace.touched || submitted) && f.recordingplace.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.recordingplace.errors?.required">Place Is
                                                                    Required
                                                                </div>
                                                            </div>
                                                            <div *ngIf="(f.recordingplace.touched || submitted) && f.recordingplace.errors?.maxlength"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.recordingplace.errors?.maxlength">Maximum
                                                                    25
                                                                    characters
                                                                    are
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label" for="date">Recording
                                                                Date</label>
                                                            <input autocomplete="off" type="date"
                                                                class="form-control username password_box" id="date"
                                                                formControlName="recordingdate" onload="this.value=''"
                                                                [ngClass]="{ 'is-invalid': (f.recordingdate.touched || submitted) && f.recordingdate.errors }"
                                                                max="{{currentDate | date:'yyyy-MM-dd'}}" />
                                                            <div *ngIf="(f.recordingdate.touched || submitted) && f.recordingdate.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.recordingdate.errors?.required">Date Is
                                                                    Required
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label"
                                                                for="numberOfPhotos">Number of Videos</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box"
                                                                id="numberOfPhotos" formControlName="NumberOfPhotos"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                placeholder="Number of Videos" autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors }" />
                                                            <div *ngIf="(f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.NumberOfPhotos.errors?.required">Number of
                                                                    Videos Is Required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label"
                                                                for="category">Type</label>
                                                            <select id="type" formControlName="type" placeholder="type"
                                                                autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.type.touched || submitted) && f.type.errors }"
                                                                class="form-controls dp ">
                                                                <option [selected] value="" disabled>Media Type</option>
                                                                <option value="Black and White">Black and White</option>
                                                                <option value="Colour">Colour / HD</option>
                                                            </select>
                                                            <div *ngIf="(f.type.touched || submitted) && f.type.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.type.errors?.required">Type Is
                                                                    Required</div>
                                                            </div>
                                                            <div *ngIf="(f.type.touched || submitted) && f.type.errors?.maxlength"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.type.errors?.maxlength">Maximum 25
                                                                    characters
                                                                    are
                                                                    allowed
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label" for="Price">Price</label>
                                                            <input autocomplete="off" type="text"
                                                                class="form-control username password_box" id="Price"
                                                                formControlName="Price"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                                placeholder="Price" autocomplete="off"
                                                                [ngClass]="{ 'is-invalid': (f.Price.touched || submitted) && f.Price.errors }" />
                                                            <div *ngIf="(f.Price.touched || submitted) && f.Price.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.Price.errors?.required">Price Is Required
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="example-wrap b">
                                                            <label class="form-control-label" for="Photos">Choose a
                                                                video</label>
                                                            <input autocomplete="off" type="file" accept="video/*"
                                                                class="choose_file_box form-control username password_box"
                                                                id="Photos" formControlName="Photos"
                                                                placeholder="Number Of Photos" autocomplete="off"
                                                                (change)="handleFileInput1($event, $event.target.files)"
                                                                [ngClass]="{ 'is-invalid': (f.Photos.touched || submitted) && f.Photos.errors }" />
                                                            <div *ngIf="( submitted) && i.files.errors?.required"
                                                                class="invalid-feedback" style="display: block;">
                                                                <div *ngIf="i.files.errors?.required"> Please select a
                                                                    file to
                                                                    upload</div>
                                                            </div>
                                                            <!-- <div *ngIf="(f.files.touched || submitted) && f.files.errors?.required"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f.files.errors?.required">Please select a
                                                                    file to upload</div>
                                                            </div> -->
                                                        </div>
                                                    </div>


                                                    <div class="col-sm-4">

                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="addinfo_btn flt flex">
                                                            <div class="submit_btn">
                                                                <button [disabled]="createForm.invalid">Submit</button>
                                                            </div>
                                                            <div class="cancel_btn">
                                                                <button (click)="onBack()">Back</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                    <!-- /.row -->
                                </div>
                            </div>
                            <div class="tab-pane fade" id="menu1">
                                <form [formGroup]="videoForm">
                                    <div class="card card-default">
                                        <div class="card-header">
                                            <h3 class="card-title card-title1">Bulk video excel details upload</h3>
                                            <button class="btn btn-primary excelsample" (click)="excel()">Download Format</button>

                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body">

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputFile">File input</label>
                                                    <form [formGroup]="excelForm">
                                                        <file-upload formControlName="files" [multiple]="multiple"
                                                            [animation]="animation"
                                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                                        </file-upload>
                                                    </form>
                                                    <div *ngIf="(submitted1) && b.files.errors?.required"
                                                        class="invalid-feedback" style="display: block;">
                                                        <div *ngIf="b.files.errors?.required"> Please select a file to
                                                            upload</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="submit_btn">
                                                        <button type="submit" (click)="bulkSubmit()">Upload</button>
                                                    </div>
                                                    <div class="cancel_btn">
                                                        <button (click)="onBack()">Back</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <!-- /.row -->
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="menu2">
                                <div class="card card-default">
                                    <div class="card-header">
                                        <h3 class="card-title">Bulk video files upload</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputFile">File input</label>
                                                <form [formGroup]="videoForm">
                                                    <file-upload formControlName="files" [multiple]="multiple"
                                                        [animation]="animation" accept="video/*"></file-upload>
                                                </form>
                                                <div *ngIf="(submitted1) && i.files.errors?.required"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="i.files.errors?.required"> Please select a file to
                                                        upload</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 submit_btn">
                                            <div class="row">
                                                <div class="submit_btn">
                                                    <button type="submit" (click)="bulkvideoSubmit()">Upload</button>
                                                </div>
                                                <div class="cancel_btn">
                                                    <button (click)="onBack()">Back</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <!-- /.row -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>