<!-- <site-header></site-header> -->
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>

<!-- add image  -->
<div class="addinfo_container flt" *ngIf="img_add==true">
    <div class="container">
        <div class="addinfo_main flt">
            <div class="addinfo_header flt">
                <div class="row">
                    <div class="col-9">
                        Add Image Information
                    </div>
                    <div class="col-3">
                        <select class="form-controls" (change)="search_filter1($event.target.value)">
                            <!-- <option [selected] value="image" disabled>Media Type</option> -->
                            <option value="image" selected>image</option>
                            <option value="video">Video</option>
                            <option value="audio">Audio</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card flt">
                <div class="card-body flt">
                    <div class="input_body flt">
                        <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)"
                            class="">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="example-wrap b flt">
                                        <label class="form-control-label" for="itemName">Item
                                            Name</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="itemName" formControlName="ItemName" onload="this.value=''"
                                            placeholder="Item Name" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.ItemName.touched || submitted) && f.ItemName.errors }" />
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.required">Item Name Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="place">Place</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="place" formControlName="Place" onload="this.value=''"
                                            placeholder="Place" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Place.touched || submitted) && f.Place.errors }" />
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.required">Place Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Category1">Category</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Category1" formControlName="Category1" onload="this.value=''"
                                            placeholder="Category" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Category1.touched || submitted) && f.Category1.errors }" />
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.required">Category Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="category">Main Category</label>
                                       
                                        <select id="category" formControlName="Category" placeholder="Category"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }"
                                            class="form-controls dp ">
                                            <option [selected] value="" disabled>Main Category Type</option>
                                            <option value="image">image</option>
                                            <option value="video" disabled>Video</option>
                                            <option value="audio" disabled>Audio</option>


                                        </select>
                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category.errors?.required">Main Category Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.Category.touched || submitted) && f.Category.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="walletNumber">Wallet
                                            Number</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="walletNumber" formControlName="WalletNumber" onload="this.value=''"
                                            placeholder="Wallet Number" autocomplete="off"
                                           
                                            [ngClass]="{ 'is-invalid': (f.WalletNumber.touched || submitted) && f.WalletNumber.errors }" />
                                        <div *ngIf="(f.WalletNumber.touched || submitted) && f.WalletNumber.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.WalletNumber.errors?.required">Wallet Number
                                                Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="date">Date</label>
                                        <input autocomplete="off" type="date" class="form-control username password_box"
                                            id="date" formControlName="Date" onload="this.value=''"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }"
                                            max="{{currentDate | date:'yyyy-MM-dd'}}" />
                                        <div *ngIf="(f.Date.touched || submitted) && f.Date.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Date.errors?.required">Date Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="numberOfPhotos">Number Of
                                            Photos</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="numberOfPhotos" formControlName="NumberOfPhotos" onload="this.value=''"
                                            placeholder="Number Of Photos" autocomplete="off"
                                           
                                            [ngClass]="{ 'is-invalid': (f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors }" />
                                        <div *ngIf="(f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.NumberOfPhotos.errors?.required">Number Of
                                                Photos Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="category">Type</label>
                                        <select id="type" formControlName="type" placeholder="type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.type.touched || submitted) && f.type.errors }"
                                            class="form-controls dp ">
                                            <option [selected] value="" disabled>Media Type</option>
                                            <option value="Black and White">Black and White</option>
                                            <option value="Colour">Colour / HD</option>
                                            <!-- <option value="HD">HD</option> -->
                                        </select>
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.required">Type Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Photos">Choose a image</label>
                                        <input autocomplete="off" type="file" accept="image/png, image/gif, image/jpeg"
                                            class="choose_file_box form-control username password_box" id="Photos"
                                            formControlName="Photos" placeholder="Number Of Photos" autocomplete="off"
                                            (change)="handleFileInput1($event, $event.target.files)"
                                            [ngClass]="{ 'is-invalid': (f.Photos.touched || submitted) && f.Photos.errors }" />
                                        <div *ngIf="(f.Photos.touched || submitted) && f.Photos.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Photos.errors?.required">Please Select Image</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">

                                </div>
                                <div class="col-sm-4">
                                    <div class="addinfo_btn flt flex">
                                        <div class="submit_btn">
                                            <button>Submit</button>
                                        </div>
                                        <div class="cancel_btn">
                                            <button (click)="onBack()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<!-- add video  -->
<div class="addinfo_container flt" *ngIf="video_add==true">
    <div class="container">
        <div class="addinfo_main flt">
            <div class="addinfo_header flt">
                <div class="row">
                    <div class="col-9">
                        Add Video Information
                    </div>
                    <div class="col-3">
                        <select class="form-controls" (change)="search_filter1($event.target.value)">
                            <!-- <option [selected] value="image" disabled>Media Type</option> -->
                            <option value="image">image</option>
                            <option value="video" selected>Video</option>
                            <option value="audio">Audio</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input_body flt">
                <form [formGroup]="settingForm1" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm1, myForm)" class="">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="itemName">Item
                                    Name</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="itemName" formControlName="ItemName" onload="this.value=''"
                                    placeholder="Item Name" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f1.ItemName.touched || submitted) && f1.ItemName.errors }" />
                                <div *ngIf="(f1.ItemName.touched || submitted) && f1.ItemName.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.ItemName.errors?.required">Item Name Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f1.ItemName.touched || submitted) && f1.ItemName.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.ItemName.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="place">Place</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="place" formControlName="Place" onload="this.value=''" placeholder="Place"
                                    autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f1.Place.touched || submitted) && f1.Place.errors }" />
                                <div *ngIf="(f1.Place.touched || submitted) && f1.Place.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Place.errors?.required">Place Is Required
                                    </div>
                                </div>
                                <div *ngIf="(f1.Place.touched || submitted) && f1.Place.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Place.errors?.maxlength">Maximum 25 characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="category">Category</label>
                                <!-- <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="category" formControlName="Category" onload="this.value=''"
                                    placeholder="Category" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }" /> -->
                                <select id="category" formControlName="Category" placeholder="Category"
                                    autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f1.Category.touched || submitted) && f1.Category.errors }"
                                    class="form-controls dp ">
                                    <option [selected] value="" disabled>Media Type</option>
                                    <option value="image" disabled>image</option>
                                    <option value="video">Video</option>
                                    <option value="audio" disabled>Audio</option>


                                </select>
                                <div *ngIf="(f1.Category.touched || submitted) && f1.Category.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Category.errors?.required">Category Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f1.Category.touched || submitted) && f1.Category.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Category.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="walletNumber">Wallet
                                    Number</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="walletNumber" formControlName="WalletNumber" onload="this.value=''"
                                    placeholder="Wallet Number" autocomplete="off"
                                   
                                    [ngClass]="{ 'is-invalid': (f1.WalletNumber.touched || submitted) && f1.WalletNumber.errors }" />
                                <div *ngIf="(f1.WalletNumber.touched || submitted) && f1.WalletNumber.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.WalletNumber.errors?.required">Wallet Number
                                        Is Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="date">Date</label>
                                <input autocomplete="off" type="date" class="form-control username password_box"
                                    id="date" formControlName="Date" onload="this.value=''"
                                    [ngClass]="{ 'is-invalid': (f1.Date.touched || submitted) && f1.Date.errors }"
                                    max="{{currentDate | date:'yyyy-MM-dd'}}" />
                                <div *ngIf="(f1.Date.touched || submitted) && f1.Date.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Date.errors?.required">Date Is Required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="numberOfPhotos">Number Of
                                    Videos</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="numberOfPhotos" formControlName="NumberOfVideos" onload="this.value=''"
                                    placeholder="Number Of Photos" autocomplete="off"
                                   
                                    [ngClass]="{ 'is-invalid': (f1.NumberOfVideos.touched || submitted) && f1.NumberOfVideos.errors }" />
                                <div *ngIf="(f1.NumberOfVideos.touched || submitted) && f1.NumberOfVideos.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.NumberOfVideos.errors?.required">Number Of
                                        Videos Is Required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="category">Type</label>
                                <!-- <input autocomplete="off" type="text" class="form-control username password_box"
                                                                                                                                    id="category" formControlName="Category" onload="this.value=''"
                                                                                                                                    placeholder="Category" autocomplete="off"
                                                                                                                                    [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }" /> -->
                                <select id="type" formControlName="type" placeholder="type" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f1.type.touched || submitted) && f1.type.errors }"
                                    class="form-controls dp ">
                                    <option [selected] value="" disabled>Media Type</option>
                                    <option value="Black and White">Black and White</option>
                                    <option value="Colour">Colour / HD</option>
                                </select>
                                <div *ngIf="(f1.type.touched || submitted) && f1.type.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.type.errors?.required">Type Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f1.type.touched || submitted) && f1.type.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.type.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="Photos">Choose a file</label>
                                <input autocomplete="off" type="file" accept="video/mp4,video/x-m4v,video/*"
                                    class="choose_file_box form-control username password_box" id="Photos"
                                    formControlName="Photos" placeholder="Number Of Photos" autocomplete="off"
                                    (change)="handleFileInput1($event, $event.target.files)"
                                    [ngClass]="{ 'is-invalid': (f1.Photos.touched || submitted) && f1.Photos.errors }" />
                                <div *ngIf="(f1.Photos.touched || submitted) && f1.Photos.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f1.Photos.errors?.required">Please Select A Video File</div>
                                </div>
                            </div>
                        </div>

                        <!-- onload="this.value=''" -->
                        <!-- -->

                        <div class="col-sm-4">

                        </div>
                        <div class="col-sm-4">
                            <div class="addinfo_btn flt flex">
                                <div class="submit_btn">
                                    <button>Submit</button>
                                </div>
                                <div class="cancel_btn">
                                    <button (click)="onBack()">Back</button>
                                </div>
                            </div>
                        </div>



                    </div>
                </form>
            </div>

        </div>

    </div>

</div>


<!-- add audio  -->
<div class="addinfo_container flt" *ngIf="audio_add==true">
    <div class="container">
        <div class="addinfo_main flt">
            <div class="addinfo_header flt">
                <div class="row">
                    <div class="col-9">
                        Add Audio Information
                    </div>
                    <div class="col-3">
                        <select class="form-controls" (change)="search_filter1($event.target.value)">
                            <!-- <option [selected] value="image" disabled>Media Type</option> -->
                            <option value="image" >image</option>
                            <option value="video">Video</option>
                            <option value="audio" selected>Audio</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="input_body flt">
                <form [formGroup]="settingForm2" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm2, myForm)" class="">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="itemName">Particulars</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="itemName" formControlName="ItemName" onload="this.value=''"
                                    placeholder="Particulars" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f2.ItemName.touched || submitted) && f2.ItemName.errors }" />
                                <div *ngIf="(f2.ItemName.touched || submitted) && f2.ItemName.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.ItemName.errors?.required">Particulars Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f2.ItemName.touched || submitted) && f2.ItemName.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.ItemName.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="place">Page No.</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="place" formControlName="Place" onload="this.value=''"
                                   
                                    placeholder="Page No." autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f2.Place.touched || submitted) && f2.Place.errors }" />
                                <div *ngIf="(f2.Place.touched || submitted) && f2.Place.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Place.errors?.required">Page No Is Required
                                    </div>
                                </div>
                                <div *ngIf="(f2.Place.touched || submitted) && f2.Place.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Place.errors?.maxlength">Maximum 25 characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="category">Category</label>
                                <!-- <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="category" formControlName="Category" onload="this.value=''"
                                    placeholder="Performer" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }" /> -->
                                <select id="category" formControlName="Category" placeholder="Category"
                                    autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f2.Category.touched || submitted) && f2.Category.errors }"
                                    class="form-controls dp">
                                    <option value="" disabled>Media Type</option>
                                    <option value="image" disabled>image</option>
                                    <option value="video" disabled>Video</option>
                                    <option value="audio">Audio</option>
                                </select>
                                <div *ngIf="(f2.Category.touched || submitted) && f2.Category.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Category.errors?.required">Category Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f2.Category.touched || submitted) && f2.Category.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Category.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="walletNumber">DVD
                                    Number</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="walletNumber" formControlName="WalletNumber" onload="this.value=''"
                                    placeholder="DVD Number" autocomplete="off"
                                   
                                    [ngClass]="{ 'is-invalid': (f2.WalletNumber.touched || submitted) && f2.WalletNumber.errors }" />
                                <div *ngIf="(f2.WalletNumber.touched || submitted) && f2.WalletNumber.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.WalletNumber.errors?.required">DVD Number
                                        Is Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="date">Date</label>
                                <input autocomplete="off" type="date" class="form-control username password_box"
                                    id="date" formControlName="Date" onload="this.value=''"
                                    [ngClass]="{ 'is-invalid': (f2.Date.touched || submitted) && f2.Date.errors }"
                                    max="{{currentDate | date:'yyyy-MM-dd'}}" />
                                <div *ngIf="(f2.Date.touched || submitted) && f2.Date.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Date.errors?.required">Date Is Required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="numberOfPhotos">Number Of
                                    Audio Files</label>
                                <input autocomplete="off" type="text" class="form-control username password_box"
                                    id="numberOfPhotos" formControlName="NumberOfPhotos" onload="this.value=''"
                                    placeholder="Number Of Audio Files" autocomplete="off"
                                   
                                    [ngClass]="{ 'is-invalid': (f2.NumberOfPhotos.touched || submitted) && f2.NumberOfPhotos.errors }" />
                                <div *ngIf="(f2.NumberOfPhotos.touched || submitted) && f2.NumberOfPhotos.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.NumberOfPhotos.errors?.required">Number Of
                                        Audio Files Is Required</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4"> -->
                        <!-- <div class="example-wrap b">
                                <label class="form-control-label" for="category">Type</label>
                             
                                <select id="type" formControlName="type" placeholder="type" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': (f2.Category.touched || submitted) && f2.type.errors }"
                                    class="form-controls dp ">
                                    <option [selected] value="" disabled>Media Type</option>
                                    <option value="Black and White">Black and White</option>
                                    <option value="Color">Color</option>
                                    <option value="HD">HD</option>


                                </select>
                                <div *ngIf="(f2.type.touched || submitted) && f2.type.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.type.errors?.required">Type Is
                                        Required</div>
                                </div>
                                <div *ngIf="(f2.type.touched || submitted) && f2.type.errors?.maxlength"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.type.errors?.maxlength">Maximum 25
                                        characters
                                        are
                                        allowed
                                    </div>
                                </div>
                            </div> -->
                        <!-- </div> -->

                        <div class="col-sm-4">
                            <div class="example-wrap b">
                                <label class="form-control-label" for="Photos">Choose a file</label>
                                <input autocomplete="off" type="file" accept=".mp3,audio/*"
                                    class="choose_file_box form-control username password_box" id="Photos"
                                    formControlName="Photos" placeholder="Number Of Photos" autocomplete="off"
                                    (change)="handleFileInput1($event, $event.target.files)"
                                    [ngClass]="{ 'is-invalid': (f2.Photos.touched || submitted) && f2.Photos.errors }" />
                                <div *ngIf="(f2.Photos.touched || submitted) && f2.Photos.errors?.required"
                                    class="invalid-feedback">
                                    <div *ngIf="f2.Photos.errors?.required">Please Select A Audio File</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">

                        </div>
                        <div class="col-sm-4">

                        </div>
                        <div class="col-sm-4">
                            <div class="addinfo_btn flt flex">
                                <div class="submit_btn">
                                    <button>Submit</button>
                                </div>
                                <div class="cancel_btn">
                                    <button (click)="onBack()">Back</button>
                                </div>
                            </div>
                        </div>



                    </div>
                </form>
            </div>

        </div>

    </div>

</div>