
import { Component, Renderer2, isDevMode } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent {

  previousUrl: string;
  loadAPI: Promise<any>;
  curYear: string = (new Date()).getFullYear().toString();

  // tslint:disable-next-line: align
  constructor(private renderer: Renderer2, private router: Router) {
    if (this.curYear != '2019') {
      this.curYear = '2019 - ' + this.curYear;

    }
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1)

          if (currentUrlSlug == 'home' || currentUrlSlug == '') {
            this.renderer.addClass(document.body, 'home-background');
          }

          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
          }
          if (currentUrlSlug == 'login' || currentUrlSlug.match(/login\?returnUrl/g)) {
            this.renderer.addClass(document.body, 'page-login');
            this.renderer.addClass(document.body, 'layout-full');
            this.renderer.addClass(document.body, 'page-dark');
          } else {

            // var OneSignal = window['OneSignal'] || [];
            // OneSignal.push(function () {
            //   OneSignal.init({
            //     appId: `${environment.oneSignalAppId}`,
            //   });
            // });

            // OneSignal.push(function () {
            //   OneSignal.setExternalUserId(localStorage.getItem(`${environment.appName}` + '_adminId'));
            // });
            this.renderer.removeClass(document.body, 'page-login');
            this.renderer.removeClass(document.body, 'layout-full');
            this.renderer.removeClass(document.body, 'page-dark');
          }
          this.previousUrl = currentUrlSlug;
        }
      });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

 myFunction() {
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
}



  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        // "/assets/vendor/babel-external-helpers/babel-external-helpers.js",
        // "/assets/vendor/popper-js/umd/popper.min.js",
        // "/assets/vendor/bootstrap/bootstrap.min.js",
        // "/assets/vendor/animsition/animsition.min.js",
        // "/assets/vendor/mousewheel/jquery.mousewheel.min.js",
        // "/assets/vendor/asscrollbar/jquery-asScrollbar.min.js",
        // "/assets/vendor/ashoverscroll/jquery-asHoverScroll.min.js",
        // "/assets/vendor/waves/waves.min.js",
        // "/assets/vendor/switchery/switchery.min.js",
        // "/assets/vendor/intro-js/intro.min.js",
        // "/assets/vendor/screenfull/screenfull.min.js",
        // "/assets/vendor/slidepanel/jquery-slidePanel.min.js",
        // "/assets/vendor/select2/select2.full.min.js",
        // "/assets/vendor/bootstrap-tokenfield/bootstrap-tokenfield.min.js",
        // "/assets/vendor/bootstrap-tagsinput/bootstrap-tagsinput.min.js",
        // "/assets/vendor/bootstrap-select/bootstrap-select.min.js",
        // "/assets/vendor/switchery/switchery.min.js",
        // "/assets/vendor/asrange/jquery-asRange.min.js",
        // "/assets/vendor/ionrangeslider/ion.rangeSlider.min.js",
        // "/assets/vendor/asspinner/jquery-asSpinner.min.js",
        // "/assets/vendor/clockpicker/bootstrap-clockpicker.min.js",
        // "/assets/vendor/ascolor/jquery-asColor.min.js",
        // "/assets/vendor/asgradient/jquery-asGradient.min.js",
        // "/assets/vendor/ascolorpicker/jquery-asColorPicker.min.js",
        // "/assets/vendor/bootstrap-maxlength/bootstrap-maxlength.min.js",
        // "/assets/vendor/jquery-knob/jquery.knob.min.js",
        // "/assets/vendor/bootstrap-touchspin/bootstrap-touchspin.min.js",
        // "/assets/vendor/jquery-labelauty/jquery-labelauty.js",
        // "/assets/vendor/bootstrap-datepicker/bootstrap-datepicker.min.js",
        // "/assets/vendor/timepicker/jquery.timepicker.min.js",
        // "/assets/vendor/datepair/datepair.min.js",
        // "/assets/vendor/datepair/jquery.datepair.min.js",
        // "/assets/vendor/jquery-strength/password_strength.js",
        // "/assets/vendor/jquery-strength/jquery-strength.min.js",
        // "/assets/vendor/multi-select/jquery.multi-select.js",
        // "/assets/vendor/typeahead-js/bloodhound.min.js",
        // "/assets/vendor/typeahead-js/typeahead.jquery.min.js",
        // "/assets/vendor/jquery-placeholder/jquery.placeholder.min.js",
        // "/assets/vendor/jquery-placeholder/jquery.placeholder.min.js",



        // "/assets/vendor/jquery-ui/jquery-ui.min.js",
        // "/assets/vendor/blueimp-tmpl/tmpl.min.js",
        // "/assets/vendor/blueimp-canvas-to-blob/canvas-to-blob.min.js",
        // "/assets/vendor/blueimp-load-image/load-image.all.min.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-process.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-image.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-audio.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-video.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-validate.js",
        // "/assets/vendor/blueimp-file-upload/jquery.fileupload-ui.js",



        // "/assets/vendor/plyr/plyr.js",
        // "/assets/js/State.min.js",
        // "/assets/js/Component.min.js",
        // "/assets/js/Plugin.min.js",
        // "/assets/js/Base.min.js",
        // "/assets/js/Config.min.js",
        // 'assets/js/adminlte.js',



        //"/assets/one-level/js/Section/Menubar.min.js",


        // "/assets/one-level/js/Section/Sidebar.min.js",
        // "/assets/one-level/js/Section/PageAside.min.js",
        // "/assets/one-level/js/Plugin/menu.min.js",
        // "/assets/js/config/colors.min.js",
        // "/assets/one-level/js/config/tour.min.js",
        // "/assets/one-level/js/Site.min.js",
        // "/assets/js/Plugin/asscrollable.min.js",
        // "/assets/vendor/asscrollable/jquery-asScrollable.min.js",
        // "/assets/js/Plugin/slidepanel.min.js",
        // "/assets/js/Plugin/switchery.min.js",
        // "/assets/js/Plugin/jquery-placeholder.min.js",
        // "/assets/js/Plugin/material.min.js",
        // "/assets/vendor/alertify/alertify.js",
        // "/assets/vendor/webui-popover/jquery.webui-popover.min.js",
        // "/assets/vendor/toolbar/jquery.toolbar.min.js",
        // "/assets/js/Plugin/webui-popover.min.js",
        // "/assets/js/Plugin/toolbar.min.js",
        // "/assets/js/Plugin/select2.min.js",
        // "/assets/one-level/examples/js/uikit/tooltip-popover.min.js",
        // "https://cdn.jsdelivr.net/momentjs/latest/moment.min.js",
        // "https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js",
        // "/assets/vendor/magnific-popup/jquery.magnific-popup.min.js",
        // "/assets/vendor/magnific-popup/jquery.magnific-popup.min.js",
        // "/assets/js/Plugin/animate-list.min.js",


    //  "/assets/js/jquery.min.js", 
     "/assets/js/bootstrap.bundle.min.js",
  "/assets/js/jquery.knob.min.js",
      "/assets/js/moment.min.js",
       "/assets/js/daterangepicker.js",
        "/assets/js/tempusdominus-bootstrap-4.min.js",
    "/assets/js/jquery.overlayScrollbars.min.js",
     "/assets/js/adminlte.js",
  "/assets/js/demo.js", 
   "/assets/js/select2.full.min.js",
  "/assets/js/jquery.flot.js",
   "/assets/js/jquery.knob.min.js",
   "/assets/js/jquery.flot.resize.min.js",
   "/assets/vendor/alertify/alertify.js",
    
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }
      // if (!isDevMode() && localStorage.getItem('groundUser')) {
      //   let node = document.createElement('script');
      //   node.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
      //   node.type = 'text/javascript';
      //   node.async = false;
      //   document.getElementsByTagName('body')[0].appendChild(node);

      //   node = document.createElement('script');
      //   node.type = 'text/javascript';
      //   node.async = false;
      //   var oneSignalId = (`${environment.apiUrl}` == 'https://services.udupihelp.com/') ? `${environment.oneSignalIdProd}` : `${environment.oneSignalIdDev}`;
      //   var inlineScript = document.createTextNode('var OneSignal = window.OneSignal || []; OneSignal.push(function() { OneSignal.init({ appId: "'+oneSignalId+'", }); });');
      //   node.appendChild(inlineScript);
      //   document.getElementsByTagName('body')[0].appendChild(node);
      // }
    }
  }
}
