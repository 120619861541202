import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { SettingsService, OrderService } from "../_services";
import { ToastrService } from "ngx-toastr";

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: "app-settings",
  templateUrl: "./addDetails.component.html",
  styleUrls: ["./addDetails.component.css"],
})
export class addDetailsComponent implements OnInit, AfterViewInit {
  settingForm: FormGroup;
  settingForm1: FormGroup;
  settingForm2: FormGroup;
  currentDate: any;
  loading = false;
  img_add = true;
  video_add = false;
  routeParam: any;
  seacrhtxt = "";
  last_page: any;
  image1: any;
  to: any;
  total: any;
  curPage = 1;
  orders$: any[];
  product_image: any[];
  fileToUpload: File;
  from: any;
  pro: any;
  imgsrc: any;
  limit = 10;
  cat_type: any;
  imageChangedEvent1: any;
  audio_add = false;
  submitted = false;
  isResetFlag: boolean = false;
  imageError: string;
  isimageaved: boolean;
  cardImageBase64: string;
  constructor(
    private setting: SettingsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService
  ) { }

  // static areEqual(c: AbstractControl): ValidationErrors | null {
  //   const keys: string[] = Object.keys(c.value);
  //   for (const i in keys) {
  //     if (i !== '0' && c.value['new_password'] !== c.value['confirm_password']) {
  //       return { areEqual: true };
  //     }
  //   }
  // }

  ngOnInit() {
    this.cat_type = "image";

    this.currentDate = new Date();

    this.selecttype(this.cat_type);
  }
  get f(): any {
    return this.settingForm.controls;
  }
  get f1(): any {
    return this.settingForm1.controls;
  }
  get f2(): any {
    return this.settingForm2.controls;
  }
  public onCancel(): void {
    this.router.navigate(["/home"]);
  }

  selecttype(cat_type) {

    if (cat_type == "image") {
      this.settingForm = this.formBuilder.group({
        ItemName: ["", [Validators.required, Validators.maxLength(25)]],
        Place: ["", [Validators.required, Validators.maxLength(25)]],
        Category: ["", [Validators.required, Validators.maxLength(25)]],
        Category1: ["", [Validators.required, Validators.maxLength(25)]],
        WalletNumber: ["", Validators.required],
        Date: ["", Validators.required],
        NumberOfPhotos: ["", Validators.required],
        type: ["", Validators.required],
        Photos: ["", Validators.required],
      });
    } else if (cat_type == "video") {
      // alert(cat_type)
      this.settingForm1 = this.formBuilder.group({
        ItemName: ["", [Validators.required, Validators.maxLength(25)]],
        Place: ["", [Validators.required, Validators.maxLength(25)]],
        Category: ["", [Validators.required, Validators.maxLength(25)]],
        WalletNumber: ["", Validators.required],
        Date: ["", Validators.required],
        NumberOfVideos: ["", Validators.required],
        type: ["", Validators.required],
        Photos: ["", Validators.required],
      });
    } else {
      this.settingForm2 = this.formBuilder.group({
        ItemName: ["", [Validators.required, Validators.maxLength(25)]],
        Place: ["", [Validators.required, Validators.maxLength(25)]],
        Category: ["", [Validators.required, Validators.maxLength(25)]],
        WalletNumber: ["", Validators.required],
        Date: ["", Validators.required],
        NumberOfPhotos: ["", Validators.required],
        Photos: ["", Validators.required],
      });
    }
  }

  onBack() {
    this.router.navigate(["/home"]);
  }



  handleFileInput1(event: any, files: FileList) {
    // console.log("event", event);
    // console.log("FileList", FileList);
    this.imageChangedEvent1 = event;

    var that = this;
    var reader = new FileReader();

    reader.onload = function (e) {

      var img = new Image();
      if (that.cat_type == "image") {
        img.onload = function () {
          that.image1 = null;
          that.image1 = img.src;
          that.fileToUpload = files.item(0);
          console.log("that.fileToUpload", that.fileToUpload);
        };

      }

      img.src = reader.result as string;
      // that.imgsrc = img.src;
      that.image1 = files.item(0);
      // alert(that.imgsrc)
    };
    reader.readAsDataURL(files[0]);
  }

  triggerFile1() {
    let element: HTMLElement = document.querySelector(
      'input[id="image1"]'
    ) as HTMLElement;
    element.click();
  }
  add_bulk(e) { }

  search_filter1(val) {
    // this.dateto = this.to_date;
    // console.log(val);
    // alert(JSON.stringify(val))
    this.pro = val;
    this.cat_type = val;
    this.selecttype(this.cat_type);
    if (val == "image") {
      this.img_add = true;
      this.video_add = false;
      this.audio_add = false;
    } else if (val == "video") {
      this.img_add = false;
      this.video_add = true;
      this.audio_add = false;
    } else {
      this.img_add = false;
      this.video_add = false;
      this.audio_add = true;
    }
    this.order
      .getCategoryData(this.seacrhtxt, "", this.limit, "", "", "", "", val)
      .subscribe(
        (data) => {
          this.orders$ = data.result;
          this.curPage = data.currentPage;
          this.total = data.result.length;
          this.from = data.currentPage;
          this.to = data.totalPages;
          this.last_page = data.totalPages;

          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  search(e) { }

  category_filter(val) {
    // this.dateto = this.to_date;
    // console.log(val);
    // alert(JSON.stringify(val))

    if (val == "image") {
      this.img_add = true;
      this.video_add = false;
      this.audio_add = false;
    } else if (val == "video") {
      this.img_add = false;
      this.video_add = true;
      this.audio_add = false;
    } else {
      this.img_add = false;
      this.video_add = false;
      this.audio_add = true;
    }
  }

  // handleChange(event): void {
  //   const newPassCtrl = this.settingForm.controls['passwords'].get('new_password');
  //   const reTypePassCtrl = this.settingForm.controls['passwords'].get('confirm_password');

  //   if (event.target.checked) {
  //     newPassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
  //     newPassCtrl.updateValueAndValidity();
  //     reTypePassCtrl.setValidators([Validators.required, Validators.maxLength(100)]);
  //     reTypePassCtrl.updateValueAndValidity();

  //     this.isResetFlag = true;
  //     newPassCtrl.enable();
  //     reTypePassCtrl.enable();
  //   } else {
  //     newPassCtrl.clearValidators();
  //     newPassCtrl.updateValueAndValidity();
  //     reTypePassCtrl.clearValidators();
  //     reTypePassCtrl.updateValueAndValidity();

  //     this.isResetFlag = false;
  //     newPassCtrl.disable();
  //     reTypePassCtrl.disable();
  //   }
  // }

  onSubmit(form, formWrap) {
    // alert(this.cat_type)
    // console.log(this.settingForm);
    this.submitted = true;
    if (this.cat_type == "image") {
      if (this.settingForm.invalid) {
        return;
      }
    }
    else if (this.cat_type == "video") {
      if (this.settingForm1.invalid) {
        return;
      }
    }
    else if (this.cat_type == "audio") {
      if (this.settingForm2.invalid) {
        return;
      }
    }
    // if (this.settingForm.invalid && this.settingForm1.invalid && this.settingForm2.invalid) {
    //   return;
    // }
    // console.log("video", this.f.Photos.value);
    document.body.classList.add("jw-modal-open");
    // this.loading = true;

    if (this.cat_type == "image") {
      // alert(this.cat_type);
      // if (this.fileToUpload == null || undefined) {
      //   return this.toastr.error("Please choose the image");
      // }
      // this.setting
      //   .save1(
      //     this.f.ItemName.value,
      //     this.f.Place.value,
      //     this.f.Category.value ? this.f.Category.value : "image",
      //     this.f.Category1.value,
      //     this.f.WalletNumber.value,
      //     this.f.Date.value,
      //     this.f.NumberOfPhotos.value,
      //     this.f.type.value,
      //     this.fileToUpload
      //   )
      //   .subscribe(
      //     (data) => {
      //       if (data.status.code == 0) {
      //         this.submitted = false;
      //         this.isResetFlag = false;
      //         document.body.classList.remove("jw-modal-open");
      //         this.loading = false;
      //         formWrap.reset();
      //         this.toastr.success(data.status.message);

      //         // alertify.success(data.status.message)
      //       } else {
      //         document.body.classList.remove("jw-modal-open");
      //         this.loading = false;
      //         this.toastr.error(data.status.message);
      //         // alertify.error(data.status.message)
      //       }
      //     },
      //     (error) => {
      //       this.toastr.error(error);
      //       // alertify.error(error)
      //       document.body.classList.remove("jw-modal-open");
      //       this.loading = false;
      //     }
      //   );
    } else if (this.cat_type == "video") {

      // if (this.image1 == null || undefined) {
      //   return this.toastr.error("Please choose the video");
      // }
      // alert(this.f1.Category.value )
      // alert( this.f1.type.value)
      this.setting
        .save(
          this.f1.ItemName.value,
          this.f1.Place.value,
          this.f1.Category.value ? this.f1.Category.value : "video",
          this.f1.WalletNumber.value,
          this.f1.Date.value,
          this.f1.NumberOfVideos.value,
          this.f1.type.value,
          // this.f.Photos.value
          this.image1

        )
        .subscribe(
          (data) => {
            if (data.code == 0) {
              this.submitted = false;
              this.isResetFlag = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              formWrap.reset();
              this.toastr.success(data.message);

              // alertify.success(data.status.message)
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.message);
              // alertify.error(data.status.message)
            }
          },
          (error) => {
            this.toastr.error(error);
            // alertify.error(error)
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    }
    else {
      // if (this.image1 == null || undefined) {
      //   return this.toastr.error("Please choose the audio");
      // }
      this.setting
        .save(
          this.f2.ItemName.value,
          this.f2.Place.value,
          this.f2.Category.value,
          this.f2.WalletNumber.value,
          this.f2.Date.value,
          this.f2.NumberOfPhotos.value,
          "",
          // this.f2.Photos.value
          this.image1
        )
        .subscribe(
          (data) => {
            if (data.code == 0) {
              this.submitted = false;
              this.isResetFlag = false;
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              formWrap.reset();
              this.toastr.success(data.message);

              // alertify.success(data.status.message)
            } else {
              document.body.classList.remove("jw-modal-open");
              this.loading = false;
              this.toastr.error(data.message);
              // alertify.error(data.status.message)
            }
          },
          (error) => {
            this.toastr.error(error);
            // alertify.error(error)
            document.body.classList.remove("jw-modal-open");
            this.loading = false;
          }
        );
    }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   $('.ItemName, .Place, .Category, .WalletNumber, .Date, .NumberOfPhotos').val('');
    // }, 500);
  }
}
