import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService, OrderService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { identifierModuleUrl } from '@angular/compiler';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './manage-orders.component.html',
  styleUrls: ['./manage-orders.component.css']
})
export class manageOrdersComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

  manageOrderform: FormGroup;
  submitted = false;

  orders$: any[];
  orders1$:any[];
  orderss$: any[];
  loading = false;
  orderId: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
  status_text = '';
  search_data = '';
  search_status = '';
  view_img: any;
  total: any;
  from: any;
  vallen: any;
  drivers: any;
  bulk_type: any;
  imgpath: any;
  datefrom: any;
  to: any;
  from_date: any;
  to_date: any;
  searchUser: any;
  limit = 10;
  code = 1;
  pro: any;
  items: any;
  routeParam: any;
  provider: any;
  add_product: any;
  val1: any;
  bulk_add = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  cat_type = "images";
  val_event: any;
  users$: any;
  dateto: any;
  sortasc: any;
  sortdecs: any;
  myInputVariable: ElementRef;
  pro$: any;
  current_date: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bulkFile: File = null;
  dtTrigger2: Subject<any> = new Subject();
  page: any
  pageCounter: any
  topage: any
  popup: any
  currentOrderID: any;
  add_edit_btn = "Add"
  accept: any;
  reject: any;
  refund: any;
  none: any;
  counts: any;

  pend_status = false;
  accept_status = false;
  reject_status = false;
  refund_status = false;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private home: HomeService,
  ) { }
  type = '1';
  seacrhtxt = '';
  S3link = 'http//rrc-chipsy.s3.ap-south-1.amazonaws.com'






  ngOnInit() {
    this.order.getCounts().subscribe(data => {
      this.counts = data.data;
      this.counts.forEach(element => {
        if (element._id == 1) {
          this.accept = element.count;
        }
        if (element._id == 2) {
          this.reject = element.count;
        }
        if (element._id == 3) {
          this.refund = element.count;
        }
        if (element._id == 0) {
          this.none = element.count;
        }
      });
    })

    this.onRefresh()
    this.img_add = true;
    this.bulk_type = "images";
    this.sortasc = false;
    this.sortdecs = true;
    var date = new Date();
  }

  reset() {
    $("input:text").val("");
    this.pend_status = false;
    this.accept_status = false;
    this.refund_status = false;
    this.reject_status = false;
    this.onRefresh();
  }

  pending(e) {
    this.pend_status = true;
    this.accept_status = false;
    this.refund_status = false;
    this.reject_status = false;
    this.order.getManageOrderpend('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.orders1$ =  this.orders$;
      
      
      console.log(this.orders$);
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });
  }

  acceptdata(e) {
    this.pend_status = false;
    this.accept_status = true;
    this.refund_status = false;
    this.reject_status = false;
    this.order.getManageOrderaccept('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });
  }

  rejectdata(e) {
    this.pend_status = false;
    this.accept_status = false;
    this.refund_status = false;
    this.reject_status = true;
    this.order.getManageOrderreject('', 1).subscribe(data => {
      console.log(data);

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });
  }

  refunddata(e) {
    this.pend_status = false;
    this.accept_status = false;
    this.refund_status = true;
    this.reject_status = false;
    this.order.getManageOrderrefund('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });
  }

  get f(): any {
    return this.manageOrderform.controls;
  }


  onRefresh() {
    this.manageOrderform = this.formBuilder.group({
      url: [""],
    });
    this.order.getManageOrder('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });


  }

  refresh1() {
    this.order.getManageOrder('', 1).subscribe(data => {

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
    }, error => {
      console.error(error);
    });

  }

  search(e) {

    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.seacrhtxt = e.target.value;
    if (this.pend_status == false && this.accept_status == false &&
      this.refund_status == false &&
      this.reject_status == false) {
      this.order.getManageOrder(e.target.value, 1).subscribe(data => {
        if (data.code == 0) {
          this.code = data.code;
        }

        this.vallen = data.data.totalPages;

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;


      })
    }



    if (this.pend_status == true) {
      this.pend_status = true;
      this.accept_status = false;
      this.refund_status = false;
      this.reject_status = false;
      this.order.getManageOrderpend(e.target.value, 1).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }


    if (this.accept_status == true) {
      this.pend_status = false;
      this.accept_status = true;
      this.refund_status = false;
      this.reject_status = false;
      this.order.getManageOrderaccept(e.target.value, 1).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }
    if (this.refund_status == true) {
      this.pend_status = false;
      this.accept_status = false;
      this.refund_status = true;
      this.reject_status = false;
      this.order.getManageOrderrefund(e.target.value, 1).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }

    if (this.reject_status == true) {
      this.pend_status = false;
      this.accept_status = false;
      this.refund_status = false;
      this.reject_status = true;
      this.order.getManageOrderreject(e.target.value, 1).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }
  }

  pageChange(e) {
    if (this.pend_status == false && this.accept_status == false &&
      this.refund_status == false &&
      this.reject_status == false) {
      this.order.getManageOrder(this.seacrhtxt, e).subscribe(data => {
        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$);
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;

        this.loading = false;
      }, error => {
        this.loading = false;
      });
    }



    if (this.pend_status == true) {
      this.pend_status = true;
      this.accept_status = false;
      this.refund_status = false;
      this.reject_status = false;
      this.order.getManageOrderpend(this.seacrhtxt, e).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }


    if (this.accept_status == true) {
      this.pend_status = false;
      this.accept_status = true;
      this.refund_status = false;
      this.reject_status = false;
      this.order.getManageOrderaccept(this.seacrhtxt, e).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }
    if (this.refund_status == true) {
      this.pend_status = false;
      this.accept_status = false;
      this.refund_status = true;
      this.reject_status = false;
      this.order.getManageOrderrefund(this.seacrhtxt, e).subscribe(data => {

        this.orders$ = data.data.docs;
        console.log(this.orders$)
        this.orders1$ = data.data.docs.cartdetails;
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }

    if (this.reject_status == true) {
      this.pend_status = false;
      this.accept_status = false;
      this.refund_status = false;
      this.reject_status = true;
      this.order.getManageOrderreject(this.seacrhtxt, e).subscribe(data => {

        this.orders$ = data.data.docs;
        this.orders1$ = data.data.docs.cartdetails;
        console.log(this.orders$)
        this.curPage = data.data.page;
        this.total = data.data.totalDocs;
        this.from = data.data.prevPage;
        this.to = data.data.nextPage;
        this.last_page = data.data.totalPages;

        this.limit = data.data.limit;
        this.page = data.data.page;
        this.pageCounter = data.data.pagingCounter;
        this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      }, error => {
        console.error(error);
      });
    }

  }
  //bulk code
  add_bulk(val) {
    this.bulk_type = val;
    // this.add_product = false;
    this.bulk_add = true;

  }

  refresh() {
    this.order.getManageOrder('', 1).subscribe(data => {

      // this.orders$ = data.result;
      // this.curPage = data.currentPage;
      // this.total = data.result.length;
      // this.from = data.currentPage;
      // this.to = data.totalPages;
      // this.last_page = data.totalPages;

      // this.orders$ = data.data.docs;
      // this.curPage = data.data.currentPage;
      // this.total = data.data.Count;
      // this.from = data.data.currentPage;
      // this.to = data.data.nextPage;
      // this.last_page = data.data.totalPages;

      this.orders$ = data.data.docs;
      this.orders1$ = data.data.docs.cartdetails;
      console.log(this.orders$)
      this.curPage = data.data.page;
      this.total = data.data.totalDocs;
      this.from = data.data.prevPage;
      this.to = data.data.nextPage;
      this.last_page = data.data.totalPages;

      this.limit = data.data.limit;
      this.page = data.data.page;
      this.pageCounter = data.data.pagingCounter;
      this.topage = (this.limit * (this.page - 1)) + this.orders$.length;
      this.dtTrigger.next();

    });
  }

  triggerBulk() {
    let element: HTMLElement = document.querySelector('input[id="bulk"]') as HTMLElement;
    element.click();
  }


  resetBulk() {
    this.myInputVariable.nativeElement.value = "";
  }




  viewDet(e, user) {
    if (user.type == "images") {
      this.view_img = "images";
    } else if (user.type == "video") {
      this.view_img = "video";
    } else {
      this.view_img = "audio";
    }
    // alert(this.view_img)
    this.imgpath = this.media_domain + user.image;

    $('#managebenModal').modal('show');

    // this.router.navigate(['/order-details'], { queryParams: { id: user._id } });
  }

  editDet(e, user) {
    // alert(JSON.stringify(user));
    this.router.navigate(['/editDetails'], { queryParams: { id: user._id, category: user.category } });
  }

  addURL(_id) {
    this.manageOrderform.controls['url'].reset()
    this.currentOrderID = _id;
    this.add_edit_btn = "Add"
    $('#exampleModalCenter').modal('show');
  }

  editURL(_id) {
    this.manageOrderform.controls['url'].reset();
    this.currentOrderID = _id;
    this.add_edit_btn = "Edit"
    $('#exampleModalCenter').modal('show');
 
    this.order.getSingleURL(this.currentOrderID).subscribe(data => {
      if (data.status.code == 0) {
        this.manageOrderform.patchValue({
          url: data.data.docs[0].link,

        })
      }
    })
  }


  submitURL(e) {
    console.log(e);
    console.log(e.value.url);

    let re = /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (!re.test(e.value.url)) {
      this.toastr.error('Please enter valid link');

      return false;
    }




    this.order.addUrl(this.currentOrderID, e.value.url).subscribe(data => {

      if(data.status.code == 0)
      {
        if(data.data.status == 1){
          this.acceptdata('');
        }
        if(data.data.status == 2){
          this.rejectdata('');
        }
        if(data.data.status == 3){
          this.refunddata('');
        }
        if(data.data.status == 0){
          this.pending('');
        }
      $('#exampleModalCenter').modal('hide');
    //  this.refresh1()
      }
    })
    $('#exampleModalCenter').modal('hide');
    // this.acceptdata('');
  }

  hideURL() {
 
    $('#exampleModalCenter').modal('hide');
  }

  addAction(order_id, e) {

    this.order.updateOrderStatus(order_id, e.target.value).subscribe(data => {
      // if(data.status.code == 0)
      // {
      this.refresh1();
      // }
      this.order.getCounts().subscribe(data => {
        this.counts = data.data;
        this.counts.forEach(element => {
          if (element._id == 1) {
            this.accept = element.count;
          }
          if (element._id == 2) {
            this.reject = element.count;
          }
          if (element._id == 3) {
            this.refund = element.count;
          }
          if (element._id == 0) {
            this.none = element.count;
          }
        });
      })
    })
  }

}
