<style type="text/css">
    .box_1 {
        background-color: #ffffff;
        padding: 45px;
    }

    .box_1,
    .box_1 h3 {
        color: #0f0c0c;
    }

    #login aside,
    #register aside {
        width: auto;
        padding: 0px 0px 0px;
        margin: auto;
        left: 0;
        top: 0;
        overflow-y: inherit;
        background-color: #fff;
        min-height: 100%;
    }

    #login aside form,
    #register aside form {
        margin-bottom: 0px;
    }

    .animated .block-reveal {
        height: auto;
        overflow: hidden;
        position: relative;
        width: 100%;
        box-shadow: 10px 12px 30px rgba(0, 0, 0, 0.2) !important;
        border-radius: 12px;
    }

    .call_section {
        background: url(/assets/website/img/login.jpg) center center no-repeat fixed;
        background-size: cover;
        min-height: 400px;
        padding: 10% 0;
    }

    .nav-tabs a {
        font-size: 1.2rem;
    }

    .reg {
        padding-bottom: 12px;
        color: #878787;
    }

    .centered {
        text-align: center;
    }

    .centered h4 {
        color: green;
    }

    @media only screen and (max-width: 480px) {
        .call_section[_ngcontent-c1] {
            padding: 30% 0;
        }

        .nav-tabs a {
            font-size: .7rem;
        }

        .h3,
        h3 {
            font-size: 1.0rem;
        }

        .h5,
        h5 {
            font-size: .8rem;
        }

        .call_section {
            padding: 25% 0;
        }

        jw-modal .jw-modal .jw-modal-body {
            margin: 5% 5% !important;
        }
    }

    button[disabled] {
        display: block;
    }

    /*input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: 1px solid transparent;
-webkit-text-fill-color: #989898;
-webkit-box-shadow: 0 0 0px 1000px #6b6b6b inset;
transition: background-color 5000s ease-in-out 0s;
}*/
</style>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- Page -->
<div class="login-page flt">
    <div class="login-box flt">
        <!-- /.login-logo -->
        <div class="card flt">
            <div class="card-body login-card-body flt">
                <div class="card_body_div flt">
                    <div class="map_div flt">
                        <div class="login_image">
                            <img src="../../assets/images/logo_small2.png" class="img-respsonsive" alt="image">
                        </div>
                        <div class="login_image_txt">
                            Let's Get Started
                        </div>
                    </div>
                    <div class="fields_div flt">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="input-group mb-3">
                                <!-- <i class="fas fa-user user_prof"></i> -->
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-user"></span>
                                    </div>
                                </div>
                                <input type="text" formControlName="username" class="form-control password_box"
                                    [ngClass]="{ 'is-invalid': (f.username.touched || submitted) && f.username.errors }"
                                    placeholder="Enter Email ID" />


                                <!-- <input type="email" class="form-control" placeholder="Enter User name"> -->

                                <div *ngIf="(f.username.touched || submitted) && f.username.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Email is required</div>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-key"></span>
                                    </div>
                                </div>
                                <input type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }"
                                    placeholder="Enter Password" />


                                <!-- <input type="password" class="form-control" placeholder="Enter Password"> -->

                                <div *ngIf="(f.password.touched || submitted) && f.password.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                            <!-- <p class="mb-3">
                                <a href="/forgot-password" class="forgot_password">I forgot my password</a>
                            </p> -->
                            <button type="submit" class="btn btn-login btn-block">Login</button>
                        </form>
                    </div>
                </div>
            </div>

            <!-- /.login-card-body -->
        </div>
    </div>
    <!-- Copyright ©
    <script type="text/javascript">
        document.write(new Date().getFullYear());
    </script> All rights reserved -->
</div>
<!-- End Page -->