import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService, OrderService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { identifierModuleUrl } from '@angular/compiler';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class bulkUploadComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

 
  orders$: any[];
  orderss$: any[];
  loading = false;
  orderId: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
  status_text = '';
  search_data = '';
  search_status = '';
  view_img: any;
  total: any;
  from: any;
  vallen: any;
  drivers: any;
  bulk_type: any;
  imgpath: any;
  datefrom: any;
  to: any;
  from_date: any;
  to_date: any;
  searchUser: any;
  limit = 10;
  code = 1;
  pro: any;
  items: any;
  routeParam: any;
  provider: any;
  add_product: any;
  val1: any;
  bulk_add = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  cat_type = "image";
  val_event: any;
  users$: any;
  dateto: any;
  sortasc: any;
  sortdecs: any;
  myInputVariable: ElementRef;
  pro$: any;
  current_date: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bulkFile: File = null;
  dtTrigger2: Subject<any> = new Subject();
  file1: any
  getTab = this.route.snapshot.queryParamMap.get("tab");
  allfile:File;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private home: HomeService,
  ) { }
  type = '1';
  seacrhtxt = '';
  S3link = 'http//rrc-chipsy.s3.ap-south-1.amazonaws.com'




  ngOnInit() {
    if (this.getTab) {
      this.search_filter2(this.getTab);
    }
    //  alert(this.getTab);

    console.log("getTab", this.getTab)
    this.img_add = true;
    this.bulk_type = "image";

    console.log(this.bulk_type);

    this.sortasc = false;
    this.sortdecs = true;
    var date = new Date();
    this.current_date = this.formatDate(date);
    if (!this.getTab) {
      this.order.getOrder('', 1, this.limit, '', '', '', '', this.cat_type).subscribe(data => {
        console.log(data.result)
        this.orders$ = data.result;

        this.curPage = data.currentPage;
        this.total = data.result.length;
        this.from = data.currentPage;
        this.to = data.totalPages;
        this.last_page = data.totalPages;
        return false;
        this.dtTrigger.next();
      }, error => {
        console.error(error);
      });
    }
  }

  sortByName(e) {
    this.order.getSortRecord('name', this.cat_type).subscribe(data => {
      this.orders$ = data.result;
      console.log(this.orders$);
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
      this.sortdecs = true;
      this.sortasc = false;
      return false;
      this.dtTrigger.next();

    })
  }

  sortByNameAsc(e) {
    this.order.getSortRecordasc(this.cat_type).subscribe(data => {
      this.orders$ = data.result;
      console.log(this.orders$);
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
      this.sortdecs = false;
      this.sortasc = true;
      return false;
      this.dtTrigger.next();
    })
  }


  search(e) {
    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.seacrhtxt = e.target.value;

    this.order.getOrder(e.target.value, 1, this.limit, '', '', '', '', this.cat_type).subscribe(data => {
      if (data.code == 0) {
        this.code = data.code;
      }

      this.vallen = this.orders$.length;
      this.orders$ = data && data.result ? data.result : '';
      this.curPage = data && data.currentPage ? data.currentPage : '';
      this.total = data && data.result && data.result.length ? data.result.length : '';
      this.from = data && data.currentPage ? data.currentPage : '';
      this.to = data && data.totalPages ? data.totalPages : '';
      this.last_page = data && data.totalPages ? data.totalPages : '';
    })
  }

  pageChange(e) {
    this.order.getCategoryData(this.seacrhtxt, e, this.limit, '', '', '', '', this.cat_type).subscribe(data => {
      this.orders$ = data.result;
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  //bulk code
  add_bulk(val) {
    this.bulk_type = val;
    // this.add_product = false;
    this.bulk_add = true;

    // image  video  audio

    if(this.bulk_type == "image" || this.bulk_type == "bulk_image")
    {
      this.cat_type="image"
    } else if(this.bulk_type == "video" || this.bulk_type == "bulk_video")
    {
      this.cat_type="video"
     
    }else if(this.bulk_type == "audio" || this.bulk_type == "bulk_audio")
    {
      this.cat_type="audio"
    }
    this.order.getOrder('', '', '', '', '', '', '', this.cat_type).subscribe(data => {
      this.orders$ = data.result;
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
    });

  }



  handleBulkFile(event: any, files: any) {  
    var that = this;

    console.log(files);

    alertify.confirm('Are you sure want to upload this record?', () => {
      that.loading = true;
      if (that.bulk_type == "image" || that.bulk_type == "video" || that.bulk_type == "audio") {
        var type;
        if (that.bulk_type == "image") {
          type = "image";
        }
        else if (that.bulk_type == "video") {
          type = "video";
        }
        else if (that.bulk_type == "audio") {
          type = "audio";
        }

        
        that.order.bulk_upload_file(files, type).subscribe(data => {
          if (data.status.code == 0) {
            this.order.getOrder('', '', '', '', '', '', '', this.cat_type).subscribe(data => {
              this.orders$ = data.result;
              this.curPage = data.currentPage;
              this.total = data.result.length;
              this.from = data.currentPage;
              this.to = data.totalPages;
              this.last_page = data.totalPages;
            });
            that.toastr.success(data.status.message);
          } else {
            that.toastr.error(data.status.message)
          }
          that.loading = false;
        })
      }

      if (that.bulk_type == "bulk_image" || that.bulk_type == "bulk_video" || that.bulk_type == "bulk_audio") {
        var type;
        if (that.bulk_type == "bulk_image") {
          type = "image";
        }
        else if (that.bulk_type == "bulk_video") {
          type = "video";
        }
        else if (that.bulk_type == "bulk_audio") {
          type = "audio";
        }

     
        that.order.bulk_upload_img(files, type).subscribe(data => {
          console.log(data.code);
          var dataa = []
          if (data && data.data) {
            (data.data).forEach(element => {
              if (element.uploadstatus == 1) {
                dataa.push(element.filename + ",")
              }
            });

            if (dataa.length > 0) {
              alert("Documents not uploaded successfully = " + dataa)
            }

            that.toastr.success("Uploaded Successfully");
           
          }
          
          that.loading = false;
        })
      }
    }, function () {
      that.toastr.error('Upload action cancelled');
    });

  }

  refresh() {
    this.order.getOrder('', '', '', '', '', '', '', this.cat_type).subscribe(data => {
      this.orders$ = data.result;
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;

    });
  }

  triggerBulk() {
    let element: HTMLElement = document.querySelector('input[id="bulk"]') as HTMLElement;
    element.click();
  }

  search_filter1(val) {
    this.pro = val;
    this.cat_type = val;
    if (val == "image") { 
      this.img_add = true;
      this.video_add = false;
      this.audio_add = false
    }
    else if (val == "video") {
      this.img_add = false;
      this.video_add = true;
      this.audio_add = false
    } else if (val == "audio") {
      this.img_add = false;
      this.video_add = false;
      this.audio_add = true
    }

    this.order.getCategoryData(this.seacrhtxt, '', this.limit, '', '', '', '', val).subscribe(data => {
      this.orders$ = data.result;
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }






  search_filter2(val) {
    this.pro = val;
    this.cat_type = val;
    if (val == 1) {
      this.val1 = "";
      this.val1 = "audio";
    }
    else if (val == 2) {
      this.val1 = "";
      this.val1 = "video"
    } else if (val == 3) {
      this.val1 = "";
      this.val1 = "image"
    }
  
    this.order.getCategoryData(this.seacrhtxt, '', this.limit, '', '', '', '', this.val1).subscribe(data => {
      this.orders$ = data.result;
      this.curPage = data.currentPage;
      this.total = data.result.length;
      this.from = data.currentPage;
      this.to = data.totalPages;
      this.last_page = data.totalPages;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }


  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [day, month, year].join('-');
  }


  firstDate(e, type) {

    var date = e.target.value
    this.from_date = this.formatDate(e.target.value)

    this.order.getOrder('', '', '', '', '', this.from_date, '', this.cat_type).subscribe(data => {
      this.orders$ = data.data.order.data;
      this.curPage = data.data.order.current_page;
      this.total = data.result.length;
      this.from = data.data.order.from;
      this.to = data.data.order.to;
      this.last_page = data.totalPages;

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  firstDate1(e, type) {
    this.datefrom = this.from_date;
    console.log(this.datefrom);
    var date = e.target.value
    this.to_date = this.formatDate(e.target.value)

    this.order.getOrder('', '', '', '', '', this.datefrom, this.to_date, this.cat_type).subscribe(data => {
      this.orders$ = data.data.order.data;
      this.curPage = data.data.order.current_page;
      this.total = data.result.length;
      this.from = data.data.order.from;
      this.to = data.data.order.to;
      this.last_page = data.totalPages;

      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }


  bulkUpload() {

  }

  viewDet(e, user) {

    console.log(user);

    if (user.mediatype == "image") {
      this.view_img = "image";
    } else if (user.mediatype == "video") {
      this.view_img = "video";
    } else {
      this.view_img = "audio";
    }
   
    this.imgpath = ''
    if(user.url)
    {
      this.imgpath = this.media_domain + user.url;
    }
    

    $('#managebenModal').modal('show');
  }

  editDet(e, user) {

    console.log(user);
    
    this.router.navigate(['/editDetails'], { queryParams: { id: user._id, category: user.mediatype } });
  }

}
