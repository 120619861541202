import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { SettingsService,OrderService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FileUploadValidators } from '@iplab/ngx-file-upload';


declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-audio-upload',
  templateUrl: './audio-upload.component.html',
  styleUrls: ['./audio-upload.component.css']
})
export class AudiouploadComponent implements OnInit {
  createForm: FormGroup;
  bulkform:FormGroup;
  imageChangedEvent1: any;
  cat_type = "audio";
  image1: any;
  fileToUpload: File;
  currentDate: any;
  submitted = false;
  submitted1 = false;
  loading=false;

  filename: any;
  public animation: boolean = true;
  public multiple: boolean = true;

  private filesControl = new FormControl(null, Validators.required);

  public excelForm = new FormGroup({
      files: this.filesControl
  });

  private filesControlaudio = new FormControl(null, Validators.required);

  public audioForm = new FormGroup({
      files: this.filesControlaudio
  });
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private setting: SettingsService,
    private route: ActivatedRoute,
    private order: OrderService,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {

    this.currentDate = new Date();

    this.createForm = this.formBuilder.group({
      itemName: ["", [Validators.required, Validators.maxLength(25)]],
      recordingplace: ["", [Validators.required, Validators.maxLength(25)]],
      Category: ["", [Validators.required, Validators.maxLength(25)]],
      audio_no: ["", [Validators.required]],
      recordingdate: ["", [Validators.required]],
      NumberOfAudios: ["", [Validators.required]],
      Audios: ["", [Validators.required]],
      PerformerInformerCollection:["",[Validators.required]],
      Price: ["", [Validators.required]],
    });
 
 

  }


  public toggleStatus(): void {
    this.filesControl.disabled ? this.filesControl.enable() : this.filesControl.disable();
}

public toggleMultiple() {
    this.multiple = !this.multiple;
}

public clear(): void {
    this.filesControl.setValue([]);
}
  get f(): any {
    return this.createForm.controls;
  }

  get b(): any {
    return this.excelForm.controls;
  }

  get i(): any {
    return this.audioForm.controls;
  }

  handleFileInput1(event: any, files: FileList) {
    // console.log("event", event);
    // console.log("FileList", FileList);
    this.imageChangedEvent1 = event;

    var that = this;
    var reader = new FileReader();

    if (!event.target.files[0].type.match("audio.*")) {
      that.toastr.error('Choose only Audios');
      return false;
    }
    reader.onload = function (e) {

  

   
      // that.imgsrc = img.src;
      that.fileToUpload = files.item(0);
      // alert(that.imgsrc)
    };
    reader.readAsDataURL(files[0]);
  }

  onSubmit(form, formWrap) {
    this.submitted = true;
   
      if (this.createForm.invalid) {
        console.error(this.createForm)
        return;
      }
      
      alertify.confirm('Are you sure want to upload this record?', () => {
        this.loading=true;
      this.setting.audiosave(
          this.f.itemName.value,
          this.f.Category.value,
          this.f.audio_no.value,
          this.f.NumberOfAudios.value,
          this.f.PerformerInformerCollection.value,
          this.f.recordingplace.value,
          this.f.recordingdate.value,
          this.fileToUpload,
          this.f.Price.value,

          
        )
        .subscribe(
          (data) => {
            console.log(data);
            if (data.status.code == 0) {
              this.submitted = false;
              this.loading = false;
              formWrap.reset();
              this.ngOnInit();
              this.toastr.success(data.status.message);
            } else {
              this.loading = false;
              this.toastr.error(data.status.message);
            }
          },
          (error) => {
            this.toastr.error(error);
            this.loading = false;
          }
        );
      }, function () {
     
      });
    
  }

 
  onBack() {
    this.router.navigate(['/ViewRRC'],{ queryParams: { type : this.cat_type } });
  }

  handleBulkFile(event: any, files: any) {  
    console.log(files);
    var that = this;
    // alertify.confirm('Are you sure want to upload this record?', () => {
    //   that.loading = true;

    //   that.order.bulk_upload_file(files, 'image').subscribe(data => {
    //       if (data.status.code == 0) {
          
    //         that.toastr.success(data.status.message);
    //       } else {
    //         that.toastr.error(data.status.message)
    //       }
    //       that.loading = false;
    //     })
      
    // }, function () {
    
    // });

  }



 


  bulkSubmit() {
    const that = this;
    that.submitted1 = true;
 
    if (that.filesControl.invalid) {
      that.toastr.error("Please upload a Audio Excel File");

      return;
    }

  

    console.error(that.filesControl.value);
    alertify.confirm('Are you sure want to upload this record?', () => {
      that.loading=true;
    that.order.bulk_upload_file(that.filesControl.value, 'audio').subscribe(data => {
      if (data.status.code == 0) {
        that.loading=false;
        that.toastr.success(data.status.message);
        that.filesControl.setValue([]);
        that.submitted1 = false;
        that.ngOnInit();
      } else {
        that.loading=false;
        that.toastr.error(data.status.message)
        that.filesControl.setValue([]);
        that.submitted1 = false;
        that.ngOnInit();
      }
    
    })
  }, function () {

    that.toastr.error('Upload action cancelled');
  });


  }

 

  bulkaudioSubmit() {
    const that = this;
    that.submitted1 = true;
 
    if (that.filesControlaudio.invalid) {
      that.toastr.error("Please upload a Audio File");

      return;
    }

  

    console.error(that.filesControlaudio.value);
    alertify.confirm('Are you sure want to upload this record?', () => {
      
      that.loading = true;
    that.order.bulk_upload_img(that.filesControlaudio.value, 'audio').subscribe(data => {
      if (data.status.code == 0) {
  
        that.toastr.success(data.status.message);
        that.filesControlaudio.setValue([]);
        that.submitted1 = false;
        that.loading = false;
        that.ngOnInit();
      } else {
        that.toastr.error(data.status.message)
        that.loading = false;
        that.filesControl.setValue([]);
        that.submitted1 = false;
        that.ngOnInit();
      }

    })
  }, function () {
    that.toastr.error('Upload action cancelled');
    that.loading = false;
  });


  }

  excel(){
    this.setting.excelsheet('audio').subscribe(fileData => {

      const blob: any = new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download","Bulk audio upload sample" );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  }

}
