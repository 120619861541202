<!-- main app container -->
<!-- <div class="jumbotron"> -->

<!-- <alert></alert> -->
<router-outlet></router-outlet>
<!-- <footer class="main-footer">
      Copyright ©<script type="text/javascript">
      document.write(new Date().getFullYear());
      </script> All rights reserved
  </footer> -->
<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
</aside>

<!-- </div> -->