
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>

<div class="wrapper flt">
    <div class="container">
        <div class="upload_sections flt">

            

            <div class="upload_section_main flt flex">

         

                <div class="upload_btn_main">
                    <input (keyup)="search($event)" class="form_control_search" type="text" name="custid"
                        placeholder=" Search for Name/Email/Phone No">
                    <img class="search_icon" src="../../assets/images/search.png" />

                </div>
            
            </div>
        </div>
        <div class="content-wrapper flt">
            <section class="content dashboard_box flt">
                <div class=" flt">
                    <div class="card">
                        <div class="card-body">

                            <div class="prod_adding table-responsive">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">

                                    <thead>
                                        <tr>
                                            <th>Sr No.</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <!-- <th>ItemName <button (click)="sortByName($event)"><i
                                                        class="fa-solid fa-arrow-down"></i></button>
                                                <button (click)="sortByNameAsc($event)"><i
                                                        class="fa-solid fa-arrow-up"></i></button>
                                            </th> -->
                                            <!-- <th>Place</th>
                                            <th>WalletNumber</th>
                                            <th>NumberOfPhotos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <!-- <td *ngIf="user && user.date;else date">{{user.date}}</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template> -->

                                            <td *ngIf="user && user.name;else Name">{{user.name}}</td>
                                            <ng-template #Name>
                                                <td>-</td>
                                            </ng-template>

                                            <td *ngIf="user && user.email;else Email">{{user.email}}</td>
                                            <ng-template #Email>
                                                <td>-</td>
                                            </ng-template>

                                            <td *ngIf="user && user.mobile;else Mobile">{{user.mobile}}</td>
                                            <ng-template #Mobile>
                                                <td>-</td>
                                            </ng-template>
                                            <!-- <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.place;else place">{{user.place}}</td>
                                            <ng-template #place>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.walletNumber;else walletNumber">
                                                {{user.walletNumber}}
                                            </td>
                                            <ng-template #walletNumber>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfPhotos;else numberOfPhotos">
                                                {{user.numberOfPhotos}}
                                            </td>
                                            <ng-template #numberOfPhotos>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           

                            <!-- pop up  -->

                            <div class="modal fade managebenmodal" id="managebenModal" tabindex="-1" role="dialog"
                                aria-labelledby="managebenModalTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="manbenmodal_main">
                                            <div class="modal-header">
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true"> <i class="far fa-window-close"></i></span>
                                                </button>

                                                <img src={{imgpath}} *ngIf="view_img=='images'" />


                                                <video width="320" height="240" controls *ngIf="view_img=='video'">
                                                    <source src={{imgpath}} type="video/mp4">
                                                </video>
                                                <audio width="320" height="240" controls *ngIf="view_img=='audio'">
                                                    <source src={{imgpath}} type="audio/mp3">
                                                </audio>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div class="no_data" *ngIf="orders$ && orders$.length<=0 || vallen==0 ">
                                No Data Found</div>
                            <div class="prod_adding">
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                        <div class="shloka_result">
                                            Showing {{pageCounter}}-{{topage}} of {{total}} Data.Page {{curPage}} of {{last_page}}
                                        </div>
                                    </div>
                                    <div class="col-sm-4" *ngIf="orders$ && orders$.length">
                                        <div class="temple_pagination pg_cls">
                                            <a class="backward" [ngClass]="{'disable':curPage==1}"
                                                (click)="pageChange(curPage-1)">
                                                <i class="fas fa-step-backward"></i></a> Page
                                            <span>{{curPage}}</span> of {{last_page}}<a
                                                [ngClass]="{'disable':curPage==last_page}" class="forward"
                                                (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>