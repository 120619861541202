import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeService, OrderService } from '../_services';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var jquery: any;
declare var moment: any;
declare var $: any;

declare var moment: any;
@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  //@ViewChild('gmap') gmapElement: ElementRef;
  stats$: any

  vendorConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Vendor', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No vendor found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Vendor', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  groundConfig = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '250px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Ground', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No ground found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Ground', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  role = localStorage.getItem(`${environment.appName}` + '_role');
  adminName = localStorage.getItem(`${environment.appName}` + '_adminName');
  status: string;

  vendors: any;
  grounds: any;
  searchForm: FormGroup;
  user$: any;
media_type:any;
  order_amount: any;
  daily_rates: any;

  gold_18: any;
  gold_22: any;
  silver: any;
  driver$: any;
  orders: any;
  orderscount: any;
  delivered: any;
  products: any;
  ord_amount: any;
  routeParam: any;
  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private OrderService: OrderService
  ) {
  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  ngOnInit() {

    // this.OrderService.getOrder('', '', '', '', '', '', '', '').subscribe(data => {

      
    //   this.orders = data.totalItemCount;

      
    // }, error => {
    //   console.error(error);
    // });

    this.OrderService.getImageCount().subscribe(data => {

      this.orderscount = data.data;

      // this.delivered = data.data.delivered;
      // this.driver$ = data.data.driver;
    }, error => {
      console.error(error);
    });


    var that = this;
    this.searchForm = this.formBuilder.group({
      book: '',
      vendors: '',
      grounds: ''
    });
    //if (localStorage.getItem(`${environment.appName}` + '_role') == "99") {

    // }
  }


  add_bulk(e) { }

  get f() { return this.searchForm.controls; }



  order(e) {
    if(e == 3){
      this.media_type = "image";
    }
    if(e == 2){
      this.media_type = "video";
    }

    if(e == 1){
      this.media_type = "audio";
    }
    // alert(JSON.stringify(e))
    this.router.navigate(['/ViewRRC'], { queryParams: { tab: e, type : this.media_type } })
  }


  userdata(e) {
    this.router.navigate(['/users']);
  }

  driverdata(e) {
    this.router.navigate(['/driver']);
  }

  // search_filter1(e) {

  // }

  search(e) { }

  filterDash() {
    var that = this;
    this.homeService.get().subscribe(data => {
      this.stats$ = data.data;
      // this.vendors = data.data.vendors;
    });
  }
  ngAfterViewInit() {
    var that = this;
    // setTimeout(() => {
    //   $('#fini').daterangepicker({
    //     autoUpdateInput: false,
    //     opens: 'left',
    //     locale: {
    //       format: 'DD/MM/YYYY'
    //     },
    //     ranges: {
    //       'Today': [moment(), moment()],
    //       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    //       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    //       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    //       'This Month': [moment().startOf('month'), moment().endOf('month')],
    //       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    //     }
    //   });
    //   $('#fini').on('apply.daterangepicker', function (ev, picker) {
    //     $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    //     $('.userFilter').attr('onclick', 'location.href="/users?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     $('.cmpFilter').attr('onclick', 'location.href="/complaints?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     $('.closeFilter').attr('onclick', 'location.href="/closed-complaints?filterDate=' + picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY') + '"')
    //     that.filterDash();
    //   });
    // }, 100);
  }



}
