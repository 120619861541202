<!-- <site-header></site-header> -->
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<!-- <div class="top_bar_menu">
    <div class="top_header">
        <div class="row_main">
            <div class="col-sm-1">
                <div class="logo_area">
                    <a href="/home"><img src="assets/image/logo_small.png" class="img-responsive" alt="logo"></a>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="header_list">
                    <ul>
                        <li class="nav-item d-none d-sm-inline-block nav_bgclr">

                            <a [routerLink]="['/home']" class="nav-link">
                                <p>
                                    Dashboard
                                </p>
                            </a>
                        </li>
                        <li class="nav-item d-none d-sm-inline-block nav_bgclr">
                            <a href="/ViewRRC" [routerLink]="['/ViewRRC']" class="nav-link">
                                <p>
                                    View Information
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/AddRRC']" class="nav-link">
                                <p>
                                    Add Information
                                </p>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a [routerLink]="['/bulk-upload']" class="nav-link active">
                                <p>
                                    Bulk Upload
                                </p>
                            </a>
                        </li>
                    </ul>
                </div>


            </div>
            <div class="col-sm-2">
                <div class="logout_main">
                    <a href="/login" class="nav-link">
                        Logout
                        <i class="fa-solid fa-power-off"></i>


                    </a>
                </div>

            </div>

        </div>
    </div>
 

</div> -->

<site-header></site-header>
<div class="wrapper flt">
    <div class="container">
        <div class="upload_sections flt">


            <div class="upload_section_main flt flex">
                <div class="upload_xl">
                    Upload Excel
                </div>
                <div class="filler"></div>
                <div class="upload_btn_main">
                    <select class="form-controls" (change)="add_bulk($event.target.value)">
                        <option value="" disabled>Bulk Action</option>
                        <option selected value="image">image</option>
                        <option value="video">Video</option>
                        <option value="audio">Audio</option>
                        <option value="bulk_image">Bulk Image</option>
                        <option value="bulk_video">Bulk Video</option>
                        <option value="bulk_audio">Bulk Audio</option>

                    </select>
                </div>

                <div class="upload_btn_main">
                    <input type="file"  multiple style="display: none;" id="bulk"
                        (click)="handleBulkFile($event, $event.target.files)"/>
                    <div class="bulk_btn"><a (click)="triggerBulk()"><i
                                class="fas fa-upload"></i>
                            Upload</a>
                    </div>
                    <!-- <input type="file" multiple (change)="handleBulkFile($event,$event.target.files)"/> -->
                </div>

                <div class="upload_btn_main" *ngIf="bulk_type=='audio'">
                    <input #myInput type="file" style="display: none;" id="bulk"
                        (change)="handleBulkFile($event, $event.target.files)">
                    <div class="bulk_btn"><a href="assets/product_bulk (audio).xlsx" download><i
                                class="fas fa-download"></i>
                            Sample Template</a>
                    </div>
                </div>
                <div class="upload_btn_main" *ngIf="bulk_type=='video'">
                    <input #myInput type="file" style="display: none;" id="bulk"
                        (change)="handleBulkFile($event, $event.target.files)">
                    <div class="bulk_btn"><a href="assets/product_bulk (video).xlsx" download><i
                                class="fas fa-download"></i>
                            Sample Template</a>
                    </div>
                </div>
                <div class="upload_btn_main" *ngIf="bulk_type=='image'">
                    <input #myInput type="file" style="display: none;" id="bulk"
                        (change)="handleBulkFile($event, $event.target.files)">
                    <div class="bulk_btn"><a href="assets/rrc-newDBsample.xlsx" download><i class="fas fa-download"></i>
                            Sample Template</a>
                    </div>
                </div>

            </div>

            <div class="col-sm-6">
                <div class="upload_section_main flt">

                </div>
            </div>


        </div>
        <div class="content-wrapper flt">
            <section class="content dashboard_box flt">
                <div class=" flt">
                    <div class="card">
                        <div class="card-body">

                            <div class="prod_adding table-responsive" *ngIf="img_add == true">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">

                                    <thead>
                                        <tr>

                                            <th>Date </th>
                                            <th>Category</th>
                                            <th>ItemName <button (click)="sortByName($event)"><i
                                                        class="fa-solid fa-arrow-down"></i></button>
                                                <button (click)="sortByNameAsc($event)"><i
                                                        class="fa-solid fa-arrow-up"></i></button>
                                            </th>
                                            <th>Place</th>
                                            <th>WalletNumber</th>
                                            <th>No. of Photos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.date;else date">{{user.date}}</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.category;else Category">{{user.category}}</td>
                                            <ng-template #Category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.name;else name"><a target="_blank"><span
                                                        class="map_cls">{{user.name}}</span></a></td>
                                            <ng-template #name>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.place;else place">{{user.place}}</td>
                                            <ng-template #place>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.walletNumber;else walletNumber">
                                                {{user.walletNumber}}
                                            </td>
                                            <ng-template #walletNumber>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfDocs;else numberOfDocs">
                                                {{user.numberOfDocs}}
                                            </td>
                                            <ng-template #numberOfDocs>
                                                <td>-</td>
                                            </ng-template>


                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>

                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- audio  -->
                            <div class="prod_adding table-responsive" *ngIf="video_add==true">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">

                                    <thead>
                                        <tr>

                                            <th>Date</th>
                                            <th>Category</th>
                                            <th>ItemName <button (click)="sortByName($event)"><i
                                                        class="fa-solid fa-arrow-down"></i></button>
                                                <button (click)="sortByNameAsc($event)"><i
                                                        class="fa-solid fa-arrow-up"></i></button>
                                            </th>
                                            <th>Place</th>
                                            <th>WalletNumber</th>
                                            <th>NumberOfPhotos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.date;else date">hai</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.category;else category">{{user.category}}</td>
                                            <ng-template #category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.place;else place">{{user.place}}</td>
                                            <ng-template #place>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.walletNumber;else walletNumber">
                                                {{user.walletNumber}}
                                            </td>
                                            <ng-template #walletNumber>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfPhotos;else numberOfPhotos">
                                                {{user.numberOfPhotos}}
                                            </td>
                                            <ng-template #numberOfPhotos>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- video  -->
                            <div class="prod_adding table-responsive" *ngIf="audio_add==true">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">

                                    <thead>
                                        <tr>

                                            <th>Date </th>
                                            <th>Category</th>
                                            <th>ItemName <button (click)="sortByName($event)"><i
                                                        class="fa-solid fa-arrow-down"></i></button>
                                                <button (click)="sortByNameAsc($event)"><i
                                                        class="fa-solid fa-arrow-up"></i></button>
                                            </th>
                                            <th>Place</th>
                                            <th>WalletNumber</th>
                                            <th>NumberOfPhotos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">

                                        <tr *ngFor="let user of orders$; let i = index">

                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.date;else date">{{user.date}}</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template>
                                            <td>Audio</td>
                                            <!-- <td *ngIf="user && user.category;else category">{{user.category}}</td> -->
                                            <ng-template #category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.place;else place">{{user.place}}</td>
                                            <ng-template #place>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.walletNumber;else walletNumber">
                                                {{user.walletNumber}}
                                            </td>
                                            <ng-template #walletNumber>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfPhotos;else numberOfPhotos">
                                                {{user.numberOfPhotos}}
                                            </td>
                                            <ng-template #numberOfPhotos>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>





                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- pop up  -->

                            <div class="modal fade managebenmodal" id="managebenModal" tabindex="-1" role="dialog"
                                aria-labelledby="managebenModalTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="manbenmodal_main">
                                            <div class="modal-header">
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true"> <i class="far fa-window-close"></i></span>
                                                </button>

                                                <img src={{imgpath}} *ngIf="view_img=='image' && imgpath" />


                                                <video width="320" height="240" controls *ngIf="view_img=='video' && imgpath">
                                                    <source src={{imgpath}} type="video/mp4">
                                                </video>
                                                <audio width="320" height="240" controls *ngIf="view_img=='audio' && imgpath">
                                                    <source src={{imgpath}} type="audio/mp3">
                                                </audio>

                                                <div *ngIf="!imgpath"> No Data Found</div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div class="no_data" *ngIf="orders$ && orders$.length<=0 || vallen==0 ">
                                No Data Found</div>
                            <div class="prod_adding">
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                        <div class="shloka_result">
                                            Showing {{from}}-{{to}} of {{total}} Data.Page {{curPage}} of {{last_page}}
                                        </div>
                                    </div>
                                    <div class="col-sm-4" *ngIf="orders$ && orders$.length">

                                        <div class="temple_pagination pg_cls">
                                            <a class="backward" [ngClass]="{'disable':curPage==1}"
                                                (click)="pageChange(curPage-1)">
                                                <i class="fas fa-step-backward"></i></a> Page
                                            <span>{{curPage}}</span> of {{last_page}}<a
                                                [ngClass]="{'disable':curPage==last_page}" class="forward"
                                                (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>