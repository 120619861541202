<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>

<div class="wrapper flt">
    <div class="container">

        <div class="addinfo_main flt">
            <div class="addinfo_header flt">
                Add Category Detail
            </div>
            <div class="card flt">
                <div class="card-body flt">
                    <div class="input_body flt">
                        <form [formGroup]="addCategoryForm" #myForm="ngForm" (ngSubmit)="onSubmit(addCategoryForm, myForm)" class="">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="example-wrap b flt">
                                        <label class="form-control-label" for="type">Main Category</label>
                                        <select id="category" formControlName="main_category" placeholder="main_category"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.main_category.touched || submitted) && f.main_category.errors }"
                                            class="form-controls dp">
                                            <option [selected] value="" disabled>Select Main Category</option>
                                            <option value="image">Image</option>
                                            <option value="video">Video</option>
                                            <option value="audio">Audio</option>
                                        </select>
                                        <div *ngIf="(f.main_category.touched || submitted) && f.main_category.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.main_category.errors?.required">Main Category Is
                                                Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="place">Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="place" formControlName="type" onload="this.value=''"
                                            placeholder="Enter Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.type.touched || submitted) && f.type.errors }" />
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.required">Type Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="addinfo_btn flt flex">
                                        <div class="submit_btn">
                                            <button>{{btnType}}</button>
                                        </div>
                                        <div class="cancel_btn">
                                            <button type="button" (click)="onBack()">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <div class="flt" [ngClass]="{'box_ovrelay':btnType=='Update'}">
            <div class="upload_sections flt">
                <div class="upload_section_main flt flex">
                    <div class="filler"></div>
                    <div class="upload_btn_main">
                        <input (keyup)="search($event)" class="form_control_search" type="text" name="custid"
                            placeholder="Search for the details ">
                        <img class="search_icon" src="../../assets/images/search.png" />
    
                    </div>
                </div>
            </div>
            <div class="content-wrapper flt">
                <section class="content dashboard_box flt">
                    <div class=" flt">
                        <div class="card">
                            <div class="card-body">
    
                                <div class="prod_adding table-responsive">
                                    <table cellspacing="0" id="table" class="table table-bordered table-hover">
    
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>Main Category</th>
                                                <th>Type</th>
                                                <th>Action</th>
    
                                              
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="orders$?.length != 0">
                                            <tr *ngFor="let user of orders$; let i = index">
                                                <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                              
    
                                                <td *ngIf="user && user.main_category;else mainCategory">{{user.main_category}}</td>
                                                <ng-template #mainCategory>
                                                    <td>-</td>
                                                </ng-template>
    
                                                <td *ngIf="user && user.type;else Type">{{user.type}}</td>
                                                <ng-template #Type>
                                                    <td>-</td>
                                                </ng-template>
    
                                               
                                            
                                                <td>
                                                    <!-- <button class="view_cls" (click)="viewDet($event,user)">View</button> -->
                                                    <button class="view_cls edit_class" (click)="editDet($event,user)">Edit</button>
                                                </td>
                                               
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
    
                                <div class="prod_adding">
                                    <div class="row paginate">
                                        <div class="col-sm-8">
                                            <div class="shloka_result">
                                                Showing {{pageCounter}}-{{topage}} of {{total}} Data.Page {{curPage}} of
                                                {{last_page}}
                                            </div>
                                        </div>
                                        <div class="col-sm-4" *ngIf="orders$ && orders$.length">
    
                                            <div class="temple_pagination pg_cls">
                                                <a class="backward" [ngClass]="{'disable':curPage==1}"
                                                    (click)="pageChange(curPage-1)">
                                                    <i class="fas fa-step-backward"></i></a> Page
                                                <span>{{curPage}}</span> of {{last_page}}<a
                                                    [ngClass]="{'disable':curPage==last_page}" class="forward"
                                                    (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        

    </div>
</div>