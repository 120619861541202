import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { AlertService, OrderService, HomeService } from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { data } from 'jquery';
import { identifierModuleUrl } from '@angular/compiler';


declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'app-orders',
  templateUrl: './viewDetails.component.html',
  styleUrls: ['./viewDetails.component.css']
})
export class viewDetailsComponent implements OnInit {
  @ViewChild('downloadZipLink', { static: false }) private downloadZipLink: ElementRef;
  media_domain = `${environment.media_domain}`;

  orders$: any[];
  orderss$: any[];
  loading = true;
  orderId: any;
  curPage = 1;
  perPage = 10;
  last_page: any;
  status_text = '';
  search_data = '';
  search_status = '';
  view_type= 'image';
  total: any;
  from: any;
  vallen: any;
  drivers: any;
  bulk_type: any;
  imgpath: any;
  videopath:any;
  audiopath:any;
  datefrom: any;
  to: any;
  from_date: any;
  to_date: any;
  searchUser: any;
  limit = 10;
  code = 1;
  pro: any;
  items: any;
  routeParam: any;
  provider: any;
  add_product: any;
  val1: any;
  bulk_add = false;
  img_add = false;
  video_add = false;
  audio_add = false;
  cat_type = "image";
  val_event: any;
  users$: any;
  dateto: any;
  sortasc: any;
  sortdecs: any;
  myInputVariable: ElementRef;
  pro$: any;
  current_date: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  bulkFile: File = null;
  dtTrigger2: Subject<any> = new Subject();
  getTab = this.route.snapshot.queryParamMap.get("tab");

  

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private order: OrderService,
    private home: HomeService,
  ) { }
  type = '1';
  seacrhtxt = '';
  S3link = 'http//rrc-chipsy.s3.ap-south-1.amazonaws.com'




  ngOnInit() {

    this.route.queryParams.subscribe(params => {
     
      if(params['type']){
        this.view_type = params['type'];
      }
    })
   
      this.order.getDetails('', 1, this.view_type).subscribe(data => {
        this.loading=false;
        this.orders$ = data.docs;
        this.curPage = data.page;
        this.total = data.docs.length;
        this.from = data.pagingCounter;
        this.to = data.totalDocs;
        this.last_page = data.totalPages;
    
      }, error => {
        console.error(error);
      });

    

  }



  search(e) {

    if (e.target.value) {
      this.searchUser = true;
    } else {
      this.searchUser = false;
    }

    this.seacrhtxt = e.target.value;

    this.order.getDetails(e.target.value, 1,  this.view_type).subscribe(data => {
      if (data.code == 0) {
        this.code = data.code;
      }

      this.orders$ = data.docs;
      this.vallen = this.orders$.length;
      this.curPage = data.page;
      this.total = data.docs.length;
      this.from = data.pagingCounter;
      this.to = data.totalDocs;
      this.last_page = data.totalPages;


    })
  }

  pageChange(e) {
    this.order.getDetails(this.seacrhtxt, e, this.view_type).subscribe(data => {
      this.orders$ = data.docs;
      this.curPage = data.page;
      this.total = data.docs.length;
      this.from = data.pagingCounter;
      this.to = data.totalDocs;
      this.last_page = data.totalPages;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
 

  search_filter1(e){

    this.view_type = e;
    this.loading=true;

    this.order.getDetails(this.seacrhtxt, 1, e).subscribe(data => {

      this.loading=false;
      this.code = data.code;
      this.orders$ = data.docs;
      console.log(this.orders$)
      this.vallen = this.orders$.length;
      this.curPage = data.page;
      this.total = data.docs.length;
      this.from = data.pagingCounter;
      this.to = data.totalDocs;
      this.last_page = data.totalPages;


    })

  }



  viewDet(e, user) {
console.log(user)
    this.imgpath='';
    this.videopath='';
    this.audiopath='';
    // if(user.url)
    // {
    //   this.imgpath = this.media_domain + user.url;
    //   this.videopath=this.media_domain + user.url;
    //   this.audiopath=this.media_domain+user.url;
    // }

    // alert(this.view_type)
    // this.imgpath = this.media_domain + user.image;

    if (user.mediatype == "image") {
      if(user.url)
    {
      this.imgpath = this.media_domain + user.url;
    }
      $('#managebenModal').modal('show');
    } else if (user.mediatype == "video") {
      if(user.url)
      {
        this.videopath = this.media_domain + user.url;
      }
      $('#managebenModal2').modal('show');
    } else if (user.mediatype == "audio"){
      if(user.url)
      {
        this.audiopath = this.media_domain + user.url;
      }
      $('#managebenModal3').modal('show');
    }


    // this.router.navigate(['/order-details'], { queryParams: { id: user._id } });
  }
  editDet(e, user) {
    // alert(JSON.stringify(user));
    this.router.navigate(['/editDetails'], { queryParams: { id: user._id, category: user.mediatype } });
  }

}
