<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>

<div class="wrapper flt">
    <div class="container">
        <div class="upload_sections flt">
            <div class="upload_section_main flt flex">

                <!-- <div class="filler"></div> -->

                <!-- <div class="upload_btn_main">
                    <select *ngIf="!val1" class="form-controls" (change)="search_filter1($event.target.value)">
                        <option [selected] value="" disabled>Media Type</option>
                        <option value="image">image</option>
                        <option value="video">video</option>
                        <option value="audio">audio</option>
                    </select>
                </div> -->

                <div class="upload_btn_main">
                    <input (keyup)="search($event)" class="form_control_search" type="text" name="custid"
                        placeholder="Search for Item Name/Wallet No/VideoNo/AudioNo">
                    <img class="search_icon" src="../../assets/images/search.png" />

                </div>

                <div class="upload_btn_main">

                    <!-- <div class="bulk_btn"><a href="javascript::void(0)">
                            RESET</a>
                    </div> -->
                </div>


                <!-- <div class="upload_xl">
                            Upload Xcel
                        </div>
                        <div class="upload_btn_main">
                            <input #myInput type="file" style="display: none;" id="bulk"
                                (change)="handleBulkFile($event, $event.target.files)">
                            <div class="bulk_btn"><a href="javascript::void(0)" (click)="triggerBulk()"><i
                                        class="fas fa-upload"></i>
                                    Upload</a>
                            </div>
                        </div>

                        <div class="upload_btn_main" *ngIf="bulk_type=='audio'">
                            <input #myInput type="file" style="display: none;" id="bulk"
                                (change)="handleBulkFile($event, $event.target.files)">
                            <div class="bulk_btn"><a href="assets/product_bulk (audio).xlsx" download><i
                                        class="fas fa-download"></i>
                                    Sample Template</a>
                            </div>
                        </div>
                        <div class="upload_btn_main" *ngIf="bulk_type=='video'">
                            <input #myInput type="file" style="display: none;" id="bulk"
                                (change)="handleBulkFile($event, $event.target.files)">
                            <div class="bulk_btn"><a href="assets/product_bulk (video).xlsx" download><i
                                        class="fas fa-download"></i>
                                    Sample Template</a>
                            </div>
                        </div>
                        <div class="upload_btn_main" *ngIf="bulk_type=='image'">
                            <input #myInput type="file" style="display: none;" id="bulk"
                                (change)="handleBulkFile($event, $event.target.files)">
                            <div class="bulk_btn"><a href="assets/rrc-newDBsample.xlsx" download><i
                                        class="fas fa-download"></i>
                                    Sample Template</a>
                            </div>
                        </div> -->

            </div>







        </div>
        <div class="inner_home flt">
            <div class="count_main flex flt">
                <div class="audio_count flt">
                    <a (click)="search_filter1('image')">
                        <div class="info-box" [ngClass]="{'active': view_type=='image'}">
                            <span class="info-box-icon cmn-icon bg-success"><img
                                    src="assets/images/images.png" /></span>

                            <div class="info-box-content flt">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text">Image</span> -->
                                Images
                            </div>
                        </div>
                    </a>
                </div>
                <div class="video_count flt">
                    <a (click)="search_filter1('video')">
                        <div class="info-box" [ngClass]="{'active': view_type=='video'}">
                            <span class="info-box-icon cmn-icon bg-success"><img
                                    src="assets/images/videos.png" /></span>

                            <div class="info-box-content flt">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text">Videos</span> -->
                                Videos
                            </div>

                        </div>
                    </a>
                </div>
                <div class="image_count flt">
                    <a (click)="search_filter1('audio')">
                        <div class="info-box" [ngClass]="{'active': view_type=='audio'}">


                            <span class="info-box-icon cmn-icon bg-success"><img
                                    src="assets/images/audios.png" /></span>

                            <div class="info-box-content flt">
                                <!-- <span class="info-box-number txt-clr-dashboard">{{orders ?orders:'0'}}</span> -->
                                <!-- <span class="info-box-text"></span> -->
                                Audios
                            </div>

                        </div>
                    </a>
                </div>

            </div>

        </div>
        <div class="content-wrapper flt">
            <section class="content dashboard_box flt">
                <div class=" flt">
                    <div class="card">
                        <div class="card-body">

                            <div class="prod_adding table-responsive" *ngIf="view_type=='image'">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">

                                    <thead>
                                        <tr>

                                            <th>Date </th>
                                            <th>Category</th>
                                            <th>ItemName</th>
                                            <th>Place</th>
                                            <th>WalletNumber Type</th>
                                            <th>NumberOfPhotos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$ && orders$.length >0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.date;else date">{{user.date}}</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.category;else Category">{{user.category}}</td>
                                            <ng-template #Category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.place;else place">{{user.place}}</td>
                                            <ng-template #place>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.wallet_number_type;else walletNumber">
                                                {{user.wallet_number_type}}
                                            </td>
                                            <ng-template #walletNumber>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfDocs;else number">
                                                {{user.numberOfDocs}}
                                            </td>
                                            <ng-template #number>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" *ngIf="user && user.url;else url"
                                                    (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <ng-template #url>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- audio  -->
                            <div class="prod_adding table-responsive" *ngIf="view_type=='video'">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Category</th>
                                            <th>ItemName</th>
                                            <th>Place</th>
                                            <th>VideoNo</th>
                                            <th>NumberOfVideos</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.recordingdate;else recordingdate">
                                                {{user.recordingdate}}</td>
                                            <ng-template #recordingdate>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.category;else category">{{user.category}}</td>
                                            <ng-template #category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.recordingplace;else recordingplace">
                                                {{user.recordingplace}}</td>
                                            <ng-template #recordingplace>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.video_no;else video_no">
                                                {{user.video_no}}
                                            </td>
                                            <ng-template #video_no>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfDocs;else number">
                                                {{user.numberOfDocs}}
                                            </td>
                                            <ng-template #number>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" *ngIf="user && user.url;else url"
                                                    (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <ng-template #url>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- video  -->
                            <div class="prod_adding table-responsive" *ngIf="view_type=='audio'">
                                <table cellspacing="0" id="table" class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Date </th>
                                            <th>Category</th>
                                            <th>ItemName </th>
                                            <th>Place</th>
                                            <th>AudioNo</th>
                                            <th>NumberOfAudios</th>
                                            <th>Type</th>
                                            <th>View</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orders$?.length != 0">
                                        <tr *ngFor="let user of orders$; let i = index">
                                            <!-- <td>{{ perPage * (curPage - 1) + i + 1 }}</td> -->
                                            <td *ngIf="user && user.recordingdate;else date">{{user.recordingdate}}</td>
                                            <ng-template #date>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.category;else category">{{user.category}}</td>
                                            <ng-template #category>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.itemName;else itemName"><a target="_blank"><span
                                                        class="map_cls">{{user.itemName}}</span></a></td>
                                            <ng-template #itemName>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user &&  user.recordingplace;else recordingplace">
                                                {{user.recordingplace}}</td>
                                            <ng-template #recordingplace>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.audio_no;else audio_no">
                                                {{user.audio_no}}
                                            </td>
                                            <ng-template #audio_no>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.numberOfDocs;else number">
                                                {{user.numberOfDocs}}
                                            </td>
                                            <ng-template #number>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user && user.type;else type">
                                                {{user.type}}
                                            </td>
                                            <ng-template #type>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" *ngIf="user && user.url;else url"
                                                    (click)="viewDet($event,user)">View</button>
                                            </td>
                                            <ng-template #url>
                                                <td>-</td>
                                            </ng-template>
                                            <td><button class="view_cls" (click)="editDet($event,user)">Edit</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>



                            <div class="no_data" *ngIf="orders$ && orders$.length<=0 || vallen==0 ">
                                No Data Found</div>
                            <div class="prod_adding">
                                <div class="row paginate">
                                    <div class="col-sm-8">
                                        <div class="shloka_result">
                                            Showing {{from}}-{{total}} of {{to}} Data.Page {{curPage}} of {{last_page}}
                                        </div>
                                    </div>
                                    <div class="col-sm-4" *ngIf="orders$ && orders$.length">

                                        <div class="temple_pagination pg_cls">
                                            <a class="backward" [ngClass]="{'disable':curPage==1}"
                                                (click)="pageChange(curPage-1)">
                                                <i class="fas fa-step-backward"></i></a> Page
                                            <span>{{curPage}}</span> of {{last_page}}<a
                                                [ngClass]="{'disable':curPage==last_page}" class="forward"
                                                (click)="pageChange(curPage+1)"><i class="fas fa-step-forward"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>



<!-- pop up  -->

<div class="modal fade managebenmodal" id="managebenModal" tabindex="-1" role="dialog"
    aria-labelledby="managebenModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="manbenmodal_main">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"> <i class="far fa-window-close"></i></span>
                    </button>

                    <div *ngIf="view_type=='image'">
                        <img src={{imgpath}} />
                    </div>







                    <!-- <div *ngIf="!imgpath"> No Data Found</div> -->


                </div>
                <!-- <div class="modal-header" *ngIf="!imgpath">
                     No Data Found
                 </div> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade managebenmodal" id="managebenModal2" tabindex="-1" role="dialog"
    aria-labelledby="managebenModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="manbenmodal_main">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"> <i class="far fa-window-close"></i></span>
                    </button>


                    <div *ngIf="view_type=='video'">

                        <video width="320" height="240" controls src={{videopath}}>
                            <source type="video/mp4">
                        </video>
                    </div>






                    <!-- <div *ngIf="!imgpath"> No Data Found</div> -->


                </div>
                <!-- <div class="modal-header" *ngIf="!imgpath">
                 No Data Found
             </div> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade managebenmodal" id="managebenModal3" tabindex="-1" role="dialog"
    aria-labelledby="managebenModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="manbenmodal_main">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"> <i class="far fa-window-close"></i></span>
                    </button>



                    <div *ngIf="view_type=='audio'">


                        <audio width="320" height="240" controls src={{audiopath}}>
                            <source type="audio/mp3">
                        </audio>
                    </div>





                    <!-- <div *ngIf="!imgpath"> No Data Found</div> -->


                </div>
                <!-- <div class="modal-header" *ngIf="!imgpath">
                     No Data Found
                 </div> -->
            </div>
        </div>
    </div>
</div>