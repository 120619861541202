<!-- <site-header></site-header> -->
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>

<site-header></site-header>

<!-- add image  -->
<div class="addinfo_container flt">
    <div class="container">
        <div class="addinfo_main flt" *ngIf="img_add==true">
            <div class="addinfo_header flt">
                Edit Image Information
            </div>
            <div class="card flt">
                <div class="card-body flt">
                    <div class="input_body flt">
                        <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)"
                            class="">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="example-wrap b flt">
                                        <label class="form-control-label" for="itemName">Item
                                            Name</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="itemName" formControlName="ItemName" onload="this.value=''"
                                            placeholder="Item Name" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.ItemName.touched || submitted) && f.ItemName.errors }" />
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.required">Item Name Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="place">Place</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="place" formControlName="Place" onload="this.value=''"
                                            placeholder="Place" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Place.touched || submitted) && f.Place.errors }" />
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.required">Place Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Category1">Category</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Category1" formControlName="Category1" onload="this.value=''"
                                            placeholder="Category" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Category1.touched || submitted) && f.Category1.errors }" />
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.required">Category Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="walletNumber">Wallet
                                            Number</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="walletNumber" formControlName="WalletNumber" placeholder="Wallet Number"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.WalletNumber && f.WalletNumber.touched || submitted) && f.WalletNumber.errors }" />
                                        <div *ngIf="(f.WalletNumber.touched || submitted) && f.WalletNumber.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.WalletNumber.errors?.required">Wallet Number
                                                Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="date">Date</label>
                                        <!-- <input autocomplete="off" type="date" class="form-control username password_box"
                                            id="date" formControlName="Date" onload="this.value=''"
                                            (change)="getdate($event.target.value)"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }"
                                            max="{{currentDate | date:'yyyy-MM-dd'}}" /> -->

                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="date" formControlName="Date"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }" />
                                        <div *ngIf="(f.Date.touched || submitted) && f.Date.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Date.errors?.required">Date Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="numberOfPhotos">Number Of
                                            Photos</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="numberOfPhotos" formControlName="NumberOfPhotos"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            placeholder="Number Of Photos" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors }" />
                                        <div *ngIf="(f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.NumberOfPhotos.errors?.required">Number Of
                                                Photos Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="category">Type</label>
                                        <!-- <input autocomplete="off" type="text" class="form-control username password_box"
                                                                                                                                            id="category" formControlName="Category" onload="this.value=''"
                                                                                                                                            placeholder="Category" autocomplete="off"
                                                                                                                                            [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.Category.errors }" /> -->
                                        <select id="type" formControlName="type" placeholder="type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Category.touched || submitted) && f.type.errors }"
                                            class="form-controls dp ">
                                            <option [selected] value="" disabled>Media Type</option>
                                            <option value="Black and White">Black and White</option>
                                            <option value="Colour">Colour / HD</option>
                                            <!-- <option value="HD">HD</option> -->
                                        </select>
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.required">Type Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.type.touched || submitted) && f.type.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.type.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Price">Price</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Price" formControlName="Price"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            placeholder="Price" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Price.touched || submitted) && f.Price.errors }" />
                                        <div *ngIf="(f.Price.touched || submitted) && f.Price.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Price.errors?.required">Price Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="WalletNumber Type">WalletNumber
                                            Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="WalletNumber Type" formControlName="wallet_number_type"
                                            onload="this.value=''" placeholder="WalletNumber Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.wallet_number_type.touched || submitted) && f.wallet_number_type.errors }" />
                                        <div *ngIf="(f.wallet_number_type.touched || submitted) && f.wallet_number_type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.wallet_number_type.errors?.required">WalletNumber Type Is
                                                Required</div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Media Type">Media Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Media Type" formControlName="mediatype" onload="this.value=''"
                                            placeholder="Media Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.mediatype.touched || submitted) && f.mediatype.errors }" />
                                        <div *ngIf="(f.mediatype.touched || submitted) && f.mediatype.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.mediatype.errors?.required">Media Type Is Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">

                                    <label class="flt"><span class="label_txt">Image</span></label>
                                    <div class="photo_static">
                                        <div class="temple_media_image flt">
                                            <img alt="no image" class="img-responsive" class="noimg"
                                                src="assets/no-image.png" *ngIf="!imagePath">
                                            <img alt="no image" class="img-responsive" src="{{imagePath}}"
                                                *ngIf="imagePath">
                                        </div>
                                    </div>

                                    <div class="upload_audio">
                                        <a (click)="uploadImage()">UPLOAD IMAGE</a>
                                        <div class="hiddenfile">
                                            <input autocomplete="off" type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                class="choose_file_box form-control username password_box"
                                                id="fileinput1" placeholder="Number Of Photos" autocomplete="off"
                                                (change)="handleFileInput2($event, $event.target.files)" />
                                        </div>
                                        <!-- <div *ngIf="(f.Photos.touched || submitted) && f.Photos.errors?.required"
                                           class="invalid-feedback">
                                           <div *ngIf="f.Photos.errors?.required">Please Select A Image</div>
                                       </div> -->
                                    </div>

                                </div>
                                <!-- <div class="col-sm-4" *ngIf="imagePath">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="category">Current Image</label>
                                        
                                        <img class="imageClass" src={{imagePath}} />


                                        <div *ngIf="(f.Photos.touched || submitted) && f.Photos.errors?.required"
                                        class="invalid-feedback">
                                        <div *ngIf="f.Photos.errors?.required">Please Select A Image</div>
                                    </div>
        
                                       
                                    </div>
                                </div> -->











                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                </div>
                                <div class="col-sm-4">
                                    <div class="addinfo_btn flt flex">
                                        <div class="submit_btn">
                                            <button type="submit">Update</button>
                                        </div>
                                        <div class="cancel_btn">
                                            <button (click)="onBack()">Back</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>


        <div class="addinfo_main flt" *ngIf="video_add==true">
            <div class="addinfo_header flt">
                Edit Video Information
            </div>
            <div class="card flt">
                <div class="card-body flt">
                    <div class="input_body flt">
                        <form [formGroup]="settingForm" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm, myForm)"
                            class="">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="example-wrap b flt">
                                        <label class="form-control-label" for="itemName">Item
                                            Name</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="itemName" formControlName="ItemName" onload="this.value=''"
                                            placeholder="Item Name" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.ItemName.touched || submitted) && f.ItemName.errors }" />
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.required">Item Name Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f.ItemName.touched || submitted) && f.ItemName.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.ItemName.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="place">Recording Place</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="place" formControlName="Place" onload="this.value=''"
                                            placeholder="Recording Place" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Place.touched || submitted) && f.Place.errors }" />
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.required">Recording Place Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Place.touched || submitted) && f.Place.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Place.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Category1">Category</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Category1" formControlName="Category1" onload="this.value=''"
                                            placeholder="Category" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Category1.touched || submitted) && f.Category1.errors }" />
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.required">Category Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f.Category1.touched || submitted) && f.Category1.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Category1.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="walletNumber">Video
                                            Number</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="walletNumber" formControlName="video_no" placeholder="Video Number"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.video_no.touched || submitted) && f.video_no.errors }" />
                                        <div *ngIf="(f.video_no.touched || submitted) && f.video_no.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.video_no.errors?.required">Video Number
                                                Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="date">Recording Date</label>
                                        <!-- <input autocomplete="off" type="date" class="form-control username password_box"
                                            id="date" formControlName="Date" onload="this.value=''"
                                            (change)="getdate($event.target.value)"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }"
                                            max="{{currentDate | date:'yyyy-MM-dd'}}" /> -->

                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="date" formControlName="Date"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }" />
                                        <div *ngIf="(f.Date.touched || submitted) && f.Date.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Date.errors?.required">Recording Date Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="performer_informer_collection">Performer
                                            Informer Collection</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="performer_informer_collection"
                                            formControlName="performer_informer_collection" onload="this.value=''"
                                            placeholder="Performer Informer Collection" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.performer_informer_collection.touched || submitted) && f.performer_informer_collection.errors }" />
                                        <div *ngIf="(f.performer_informer_collection.touched || submitted) && f.performer_informer_collection.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.performer_informer_collection.errors?.required">Performer
                                                Informer Collection Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f1.performer_informer_collection.touched || submitted) && f1.performer_informer_collection.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.performer_informer_collection.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="numberOfPhotos">Number Of
                                            Videos</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="numberOfPhotos" formControlName="NumberOfPhotos" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            placeholder="Number Of Videos" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors }" />
                                        <div *ngIf="(f.NumberOfPhotos.touched || submitted) && f.NumberOfPhotos.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.NumberOfPhotos.errors?.required">Number Of
                                                Videos Is Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Price">Price</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Price" formControlName="Price"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            placeholder="Price" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.Price.touched || submitted) && f.Price.errors }" />
                                        <div *ngIf="(f.Price.touched || submitted) && f.Price.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.Price.errors?.required">Price Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-4" *ngIf="img_add==true">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="WalletNumber Type">WalletNumber
                                            Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="WalletNumber Type" formControlName="wallet_number_type"
                                            onload="this.value=''" placeholder="WalletNumber Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.wallet_number_type.touched || submitted) && f.wallet_number_type.errors }" />
                                        <div *ngIf="(f.wallet_number_type.touched || submitted) && f.wallet_number_type.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.wallet_number_type.errors?.required">WalletNumber Type Is
                                                Required</div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Media Type">Media Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Media Type" formControlName="mediatype" onload="this.value=''"
                                            placeholder="Media Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f.mediatype.touched || submitted) && f.mediatype.errors }" />
                                        <div *ngIf="(f.mediatype.touched || submitted) && f.mediatype.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f.mediatype.errors?.required">Media Type Is Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4" *ngIf="img_add==true">

                                    <label class="flt"><span class="label_txt">Image</span></label>
                                    <div class="photo_static">
                                        <div class="temple_media_image flt">
                                            <img alt="no image" class="img-responsive" class="noimg"
                                                src="assets/no-image.png" *ngIf="!imagePath">
                                            <img alt="no image" class="img-responsive" src="{{imagePath}}"
                                                *ngIf="imagePath">
                                        </div>
                                    </div>

                                    <div class="upload_audio">
                                        <a (click)="uploadImage()">UPLOAD IMAGE</a>
                                        <div class="hiddenfile">
                                            <input autocomplete="off" type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                class="choose_file_box form-control username password_box"
                                                id="fileinput1" placeholder="Number Of Photos" autocomplete="off"
                                                (change)="handleFileInput2($event, $event.target.files)" />
                                        </div>
                                        <!-- <div *ngIf="(f.Photos.touched || submitted) && f.Photos.errors?.required"
                                           class="invalid-feedback">
                                           <div *ngIf="f.Photos.errors?.required">Please Select A Image</div>
                                       </div> -->
                                    </div>
                                </div>


                                <div class="col-sm-4" *ngIf="video_add==true">
                                    <label class="flt"><span class="label_txt">Video</span></label>
                                    <div class="photo_static">
                                        <img alt="no image" class="img-responsive" class="noimg"
                                            src="assets/no-video.png" *ngIf="!imagePath">
                                        <div class="temple_media_image flt" *ngIf="imagePath">

                                            <video controls (click)="playVideo($event)" #videoPlayer
                                                src="{{imagePath}}">
                                                <source type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>

                                    <div class="upload_audio">
                                        <a (click)="uploadImage()">UPLOAD VIDEO</a>
                                        <div class="hiddenfile">
                                            <input autocomplete="off" type="file" accept="video/mp4,video/x-m4v,video/*"
                                                class="choose_file_box form-control username password_box"
                                                id="fileinput1" placeholder="Number Of Photos" autocomplete="off"
                                                (change)="handleFileInput2($event, $event.target.files)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                </div>
                                <div class="col-sm-4">
                                    <div class="addinfo_btn flt flex">
                                        <div class="submit_btn">
                                            <button>Update</button>
                                        </div>
                                        <div class="cancel_btn">
                                            <button (click)="onBack()">Back</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>


        <div class="addinfo_main flt" *ngIf="audio_add==true">
            <div class="addinfo_header flt">
                Edit Audio Information
            </div>
            <div class="card flt">
                <div class="card-body flt">
                    <div class="input_body flt">
                        <form [formGroup]="settingForm1" #myForm="ngForm" (ngSubmit)="onSubmit(settingForm1, myForm)"
                            class="">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="example-wrap b flt">
                                        <label class="form-control-label" for="itemName">Item
                                            Name</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="itemName" formControlName="ItemName" onload="this.value=''"
                                            placeholder="Item Name" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.ItemName.touched || submitted) && f1.ItemName.errors }" />
                                        <div *ngIf="(f1.ItemName.touched || submitted) && f1.ItemName.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.ItemName.errors?.required">Item Name Is
                                                Required</div>
                                        </div>
                                        <div *ngIf="(f1.ItemName.touched || submitted) && f1.ItemName.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.ItemName.errors?.maxlength">Maximum 25
                                                characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="place">Place</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="place" formControlName="Place" onload="this.value=''"
                                            placeholder="Place" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.Place.touched || submitted) && f1.Place.errors }" />
                                        <div *ngIf="(f1.Place.touched || submitted) && f1.Place.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Place.errors?.required">Place Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f1.Place.touched || submitted) && f1.Place.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Place.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Category1">Category</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Category1" formControlName="Category1" onload="this.value=''"
                                            placeholder="Category" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.Category1.touched || submitted) && f1.Category1.errors }" />
                                        <div *ngIf="(f1.Category1.touched || submitted) && f1.Category1.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Category1.errors?.required">Category Is Required
                                            </div>
                                        </div>
                                        <div *ngIf="(f1.Category1.touched || submitted) && f1.Category1.errors?.maxlength"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Category1.errors?.maxlength">Maximum 25 characters
                                                are
                                                allowed
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="audio_no">Audio
                                            Number</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="audio_no" formControlName="audio_no" placeholder="Audio Number"
                                            autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.audio_no.touched || submitted) && f1.audio_no.errors }" />
                                        <div *ngIf="(f1.audio_no.touched || submitted) && f1.audio_no.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.audio_no.errors?.required">Audo Number
                                                Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="date">Date</label>
                                        <!-- <input autocomplete="off" type="date" class="form-control username password_box"
                                            id="date" formControlName="Date" onload="this.value=''"
                                            (change)="getdate($event.target.value)"
                                            [ngClass]="{ 'is-invalid': (f.Date.touched || submitted) && f.Date.errors }"
                                            max="{{currentDate | date:'yyyy-MM-dd'}}" /> -->

                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="date" formControlName="Date"
                                            [ngClass]="{ 'is-invalid': (f1.Date.touched || submitted) && f1.Date.errors }" />
                                        <div *ngIf="(f1.Date.touched || submitted) && f1.Date.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Date.errors?.required">Date Is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="performer_informer_collection">Performer
                                            Information Collection</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="performer_informer_collection"
                                            formControlName="performer_informer_collection" onload="this.value=''"
                                            placeholder="Performer Information Collection" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.performer_informer_collection.touched || submitted) && f1.performer_informer_collection.errors }" />
                                        <div *ngIf="(f1.performer_informer_collection.touched || submitted) && f1.performer_informer_collection.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.performer_informer_collection.errors?.required">Performer
                                                Information Collection is Required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="numberOfDocs">Number Of Audio</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="numberOfDocs"
                                            formControlName="numberOfDocs" onload="this.value=''"
                                            placeholder="Number Of Audio" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.numberOfDocs.touched || submitted) && f1.numberOfDocs.errors }" />
                                        <div *ngIf="(f1.numberOfDocs.touched || submitted) && f1.numberOfDocs.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.numberOfDocs.errors?.required">Performer
                                                Number of Audio is Required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Price">Price</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Price" formControlName="Price"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            placeholder="Price" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.Price.touched || submitted) && f1.Price.errors }" />
                                        <div *ngIf="(f1.Price.touched || submitted) && f1.Price.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.Price.errors?.required">Price Is Required</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-4">
                                    <div class="example-wrap b">
                                        <label class="form-control-label" for="Media Type">Media Type</label>
                                        <input autocomplete="off" type="text" class="form-control username password_box"
                                            id="Media Type" formControlName="mediatype" onload="this.value=''"
                                            placeholder="Media Type" autocomplete="off"
                                            [ngClass]="{ 'is-invalid': (f1.mediatype.touched || submitted) && f1.mediatype.errors }" />
                                        <div *ngIf="(f1.mediatype.touched || submitted) && f1.mediatype.errors?.required"
                                            class="invalid-feedback">
                                            <div *ngIf="f1.mediatype.errors?.required">Media Type Is Required</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-4" *ngIf="audio_add==true">
                                    <label class="flt"><span class="label_txt">Audio</span></label>
                                    <div class="photo_static">
                                        <img alt="no image" class="img-responsive" class="noimg"
                                            src="assets/no-audio.png" *ngIf="!imagePath">
                                        <div class="temple_media_image flt" *ngIf="imagePath">
                                            <!-- <audio width="320" height="240" id="file1">  </audio> -->
                                            <audio width="320" height="240" id="file1" controls src={{imagePath}}>
                                                <!-- <source type="audio/mp3"> -->
                                            </audio>
                                        </div>
                                    </div>

                                    <div class="upload_audio">
                                        <a (click)="uploadImage()">UPLOAD AUDIO</a>
                                        <div class="hiddenfile">

                                            <input autocomplete="off" type="file" accept="audio/*"
                                                class="choose_file_box form-control username password_box"
                                                id="fileinput1" placeholder="Number Of Photos" autocomplete="off"
                                                (change)="handleFileInput1($event, $event.target.files)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                </div>
                                <div class="col-sm-4">
                                    <div class="addinfo_btn flt flex">
                                        <div class="submit_btn">
                                            <button>Update</button>
                                        </div>
                                        <div class="cancel_btn">
                                            <button (click)="onBack()">Back</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>